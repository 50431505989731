<template>
  <div class="text-center">
    <v-dialog :value="lungDialog" content-class="lung-dialog-wrapper uswds" persistent width="95vw">
      <v-card>
        <v-card-title>
          Select Lung Disease

          <div class="btn-close">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false"
              @click="$emit('lungDialogClose')">mdi-close-thick</v-icon>
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="tree-container pa-0">
              <!-- tree component here -->
              <tree
                ref="tree"
                v-model="currentData"
                :height="600"
                :data="treeData"
                class="tree"
                @clickedNode="onClickNode"
              >
              </tree>
            </v-col>
            <v-col cols="12">
              <p>Disease Selected: 
                <v-chip class="rounded"
                  v-for="(item, index) in lungSearch" :key="item"
                  close rounded small
                  @click:close="removeDisease(index)"
                >
                  {{item}}
                </v-chip>
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn depressed color="primary" @click="addLungSearch">Add disease</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="outlined" @click="$emit('lungDialogClose')">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Tree from '@/components/collapsibleTree/Tree.vue';

export default {
  components: { Tree },
  name: 'LungDialog',
  props: {
    lungDialog: {
      default: false,
    },
  },
  data() {
    return {
      currentData: null,
      events: [],
    };
  },
  mounted() {},
  methods: {
    async do(action) {
      if (this.currentData) {
        this.isLoading = true;
        await this.$refs['tree'][action](this.currentData);
        this.isLoading = false;
      }
    },
    getId(node) {
      return node.id;
    },
    onClickNode(evt) {
      this.currentData = evt.element;
      this.onEvent('clickedNode', evt);
    },
    onEvent(eventName, data) {
      this.events.push({ eventName, data: data.data });
    },
    addLungSearch() {
      this.$store.dispatch('diseaseStore/addDisease', {
        type: 'lung',
        diseaseName: this.currentData.data.name
      })
    },
    removeDisease(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: 'lung',
        diseaseIndex: index
      })
    }
  },
  computed: {
    treeData() {
      return this.$store.getters['diseaseStore/getLungData']
    },
    lungSearch() {
      return this.$store.getters['diseaseStore/getDisease'].lung
    }
  }
};
</script>

<style lang="scss"></style>
