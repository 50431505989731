import { isEqual } from 'lodash'
import { truthy } from '@/services/CommonService';
import { criteriaCcodeDictionary } from '@/store/initState';
import fetchService from '@/services/fetchService'
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('notificationStore', {
      n_hide: 'hide',
      n_show: 'show',
    }),
    generatePayload(patientInfo, disease, biomarkers, testResults, tempGeoInfo, currentGeoInfo, uuid = '') {
      let ccodes = {};
      let disease_codes = [];
      let biomarker_codes = [];

      let search_info = {
        disease,
        patientInfo,
        biomarkers,
        testResults
      }

      /* disease */
      let prepareDiseaseData = [];
      for (let key in disease) {
        if (key !== 'diseaseModifiers') {
          prepareDiseaseData.push(...disease[key])
        }
      }
      if (prepareDiseaseData.length == 0) {
        this.diseaseAlertSnackbar = true;
      } else {
        this.formValid = false; //temp disabled for submitting

        if (!isEqual(tempGeoInfo, currentGeoInfo)) {
          //console.log('isEqual*****************')
          let tempGeoInfoArrFiltered = tempGeoInfo.filter(geo => geo.lat !== '')
          //if (tempGeoInfoArrFiltered.length > 0) {
          this.t_fetchTrialsForDistanceNoUpdateArr({ geoArr: tempGeoInfoArrFiltered })
          //}
          this.$store.commit('criteriaStore/UPDATE_GEO_ARR', tempGeoInfo)
        }

        //save to summaryStore
        if (patientInfo.age == '') {
          //set ageUnit back to default 'Years' if no age value 
          //otherwise isPatientInfoDefault @summaryStore will evaluate incorrectly
          //prevent for icon render on summary header
          patientInfo.ageUnit = 'Years'
        }
        this.$store.commit('summaryStore/UPDATE_SUMMARY', {
          patientInfo,
          disease,
          biomarkers,
          testResults
        })

        if (this.firstTimeFindSearch == true) {
          //this.firstTimeFindSearch = false;
          this.initParticipantsAttr = setInitParticipantAttrs(patientInfo, biomarkers, testResults);
          //this.$store.commit('trialsStore/UPDATE_PARTICIPANT_ATTR', initParticipantsAttr)
        }

        /* patientInfo */
        for (let key in patientInfo) {
          if (truthy(patientInfo[key])) {
            if (key.includes('multiple') && patientInfo[key] !== 2) {
              getCodeFromCriteriaDict(ccodes, key, patientInfo[key])
            } else if (key == 'age') {
              let ageInYears = convertAgeInYears(patientInfo.age, patientInfo.ageUnit)
              getCodeFromCriteriaDict(ccodes, key, ageInYears)
            } else if (key == 'therapy') {
              patientInfo.therapy.forEach(item => {
                ccodes[item.code] = "YES"
              })
            } else if (key == 'weight' || key == 'height' || key == 'BMI') {
              getCodeFromCriteriaDict(ccodes, key, patientInfo[key])
            } else if (key == 'zipcode') { //no more use
              if (patientInfo[key].length == 5) {
                if (patientInfo.zipError === '') {
                  ccodes.latitude = patientInfo.geo.lat;
                  ccodes.longitude = patientInfo.geo.lon;
                }
              }
            }
          }
          if (key == 'zipcode' && patientInfo.zipcode.length !== 5) {
            this.$store.commit('trialsStore/RESET_DISTANCE')
          }
        }

        /* biomarkers */
        for (let key in biomarkers) {
          let biomarkersMultipleInfo = this.$store.getters['criteriaStore/getBiomarkersMultipleInfo']
          if (key.includes('multiple')) {
            getCodeFromBiomarkersMultipleInfo(biomarker_codes, key, biomarkers[key], biomarkersMultipleInfo)
          } else {
            biomarkers[key].forEach(item => {
              //ccodes[item.code] ="YES"
              biomarker_codes.push(item.code)
            })
          }
        }

        /* testResults */
        for (let key in testResults) {
          if (truthy(testResults[key])) {
            if (key == 'wbc' || key == 'platelets') {
              getCodeFromCriteriaDict(ccodes, key, testResults[key])
            } else if (key == 'performance') {
              ccodes[testResults[key].code] = "YES"
            } else if (key.includes('multiple') && testResults[key] !== 2) {
              getCodeFromCriteriaDict(ccodes, key, testResults[key])
            }
          }
        }

        this.n_show({
          type: 'alert-info',
          msg: 'Creating list of recommended matches...',
          show: true
        });

        /* disease */
        fetchService.postDataFiles('ccodes_from_display_names', {
          display_name: prepareDiseaseData
        })
          .then(res => {
            for (let key in res) {
              disease_codes.push(...res[key])
            }
            let prePayload = {
              disease_codes,
              ccodes,
              biomarker_codes,
              disease_modifiers: disease.diseaseModifiers.map(mod => {
                return criteriaCcodeDictionary[mod]
              }),
              search_info: JSON.stringify(search_info)
            }
            if (uuid !== '') {
              prePayload.session_uuid = uuid
            }
            this.findMatches({
              ...prePayload
            });
          })
      }
    },
    findMatches(payload) {
      if (this.firstTimeFindSearch == true) {
        payload.firstTimeFindSearch = this.firstTimeFindSearch
        payload.initParticipantsAttr = this.initParticipantsAttr
        //this.firstTimeFindSearch = false;
      }
      this.t_findMatches(payload)
        .then(data => {
          this.$store.dispatch('searchStore/fetchRecentCriteria');
          setTimeout(() => {
            this.formValid = true;
            this.isSearching = false;
            this.n_hide();
            this.sectionTabIndex = 1;
            window.scrollTo(0, 0)
          }, 500)
        });
    }
  }
}

function setInitParticipantAttrs(patientInfo, biomarkers, testResults) {
  let pAttrs = ['disease'];
  if (patientInfo.age !== '') pAttrs.push('age_matches');
  if (patientInfo.BMI !== '') pAttrs.push('BMI_matches');
  if (patientInfo.sex_multiple !== 2) pAttrs.push('gender_matches');
  if (patientInfo.therapy.length > 0) pAttrs.push('pt_inc_matches');
  if (patientInfo.ableToSwallow_multiple !== 2) pAttrs.push('swallow_matches');

  let isBiomarkersSelected = Object.keys(biomarkers).some(key => {
    if (key == 'biomarkersSelected') {
      if (biomarkers[key].length > 0) {
        return true
      }
    } else {
      if (biomarkers[key] !== 2) {
        return true
      }
    }
    return false
  })
  if (isBiomarkersSelected == true) pAttrs.push('biomarker_inc_matches', 'biomarker_exc_matches');
  if (patientInfo.brainCNS_multiple !== 2) pAttrs.push('bmets_matches');

  if (testResults.hiv_multiple !== 2) pAttrs.push('hiv_exc_matches');
  if (testResults.wbc !== '') pAttrs.push('wbc_matches');
  if (testResults.platelets !== '') pAttrs.push('plt_matches');
  if (testResults.hepatitisB_multiple !== 2) pAttrs.push('hbv_matches');
  if (testResults.hepatitisC_multiple !== 2) pAttrs.push('hcv_matches');
  if (testResults.solidOrgan_multiple !== 2) pAttrs.push('so_transplant_matches');

  pAttrs.push('perf_matches')

  return pAttrs
}

function getCodeFromCriteriaDict(obj, key, value) {
  if (key === 'sex_multiple') {
    obj[criteriaCcodeDictionary[key][value]] = 'YES';
  } else if (key === 'age' || key === 'wbc' || key === 'platelets') {
    obj[criteriaCcodeDictionary[key]] = parseFloat(value);
  } else if (key == 'weight' || key == 'height' || key == 'BMI') {
    obj[criteriaCcodeDictionary[key]] = parseFloat(value);
  } else if (key == 'ableToSwallow_multiple') {
    obj[criteriaCcodeDictionary[key]] = parseFloat(value) == 0 ? 'NO' : 'YES';
  } else {
    if (parseInt(value) == 0) {
      obj[criteriaCcodeDictionary[key]] = 'YES';
    } else if (parseInt(value) == 1) {
      obj[criteriaCcodeDictionary[key]] = 'NO';
    }
  }
}

function getCodeFromBiomarkersMultipleInfo(arr, key, value, biomarkersMultipleInfo) {
  if (value === 0) {
    biomarkersMultipleInfo[key].positive.forEach(item => {
      //obj[item.code] = 'YES';
      arr.push(item.code)
    })
  } else if (value === 1) {
    biomarkersMultipleInfo[key].negative.forEach(item => {
      //obj[item.code] = 'YES';
      arr.push(item.code)
    })
  }
}

function convertAgeInYears(age, unit) {
  if (unit == 'Months') {
    return age / 12;
  }
  return age
}