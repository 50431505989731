<template>
  <div class="text-center">
    <v-dialog :content-class="diseaseType + '-dialog-wrapper'" :id="diseaseType + '-dialog-wrapper'" 
      :value="diseaseDialog" persistent width="95vw" >
      <v-card>
        <v-card-title>
          {{ dialogTitle }}

          <v-spacer></v-spacer>

          <v-menu v-if="tracePathArr.length > 0" offset-y top open-on-hover max-width="1000px" max-height="80vh" >
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" small class="mt-4 mr-4"
                >{{ tracePathArr.length }}
                {{ tracePathArr.length == 1 ? 'Path' : 'Paths' }}</v-chip
              >
            </template>

            <v-list>
              <v-list-item
                :key="index"
                v-for="(item, index) in tracePathInfoArr"
              >
                <v-list-item-title class="chip-multilines py-1">
                  <v-chip @click="expandSignlePath(index)">
                    {{ index + 1 }}: {{ item.join(' &#11157; ') }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-autocomplete id="stage-search" :items="treeFlatData" label="" clearable hide-details 
            v-model="targetNode" @change="getTracePathArr" >
          </v-autocomplete>
          <v-btn data-test="search-disease" small color="primary" @click="expandNodes" class="ml-4 mt-3" >
            Search</v-btn >
          <v-spacer></v-spacer>
          <div class="btn-close">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false" @click="$emit('diseaseDialogClose')" >
              mdi-close-thick</v-icon >
          </div>
        </v-card-title>

        <v-card-text class="mt-0 pt-0">
          <v-row>
            <v-col cols="12" class="pr-0 pt-2 d-flex align-center">
              <p class="mb-1">
                Disease Selected:
                <transition-group name="fade">
                  <v-chip class="rounded my-1" v-for="(item, index) in diseaseSearch" :key="item" 
                    close rounded small @click:close="removeDisease(index)" >
                    {{ item }}
                  </v-chip>
                </transition-group>
              </p>
              <v-spacer></v-spacer>
              <v-btn data-test="add-disease" :disabled="currentData == null" depressed color="primary" @click="addDisease" >Add disease</v-btn >
            </v-col>

            <v-col cols="12" :class="{ grabbing: grabbing }" id="tree-container" class="tree-container pa-0" >
              <!-- tree component here -->
              <tree
                ref="tree"
                v-model="currentData"
                :height="600"
                :data="treeData"
                :duration="350"
                class="tree"
                @clickedNode="onClickNode"
                @onMD="grabbing = true"
                @onMU="grabbing = false"
              >
              </tree>
              <div id="trial-tooltip" 
                style="position: absolute; background: #1b1b1b; color: #FFFFFF; font-size: 14px; margin: 0;
                        border-radius: 5px; border: 2px solid #FFFFFF; padding: 3px 8px; display: none;"></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getMatchedNodeArr } from '@/store/buildTree';
import { mapFilters } from '@/filters/mapFilters';
import Tree from '@/components/collapsibleTree/Tree.vue';

export default {
  name: 'TreeGeneralDialog',
  components: { Tree },
  props: {
    diseaseDialog: { default: false },
    dialogTitle: { required: true, type: String },
    diseaseType: { required: true, type: String },
    treeData: {required: true, type: Object},
    treeFlatData: {required: true, type: Array}
  },
  data() {
    return {
      currentData: null,
      events: [],
      grabbing: false,
      targetNode: '',
      tracePathArr: [],
      tracePathInfoArr: [],
    };
  },
  methods: {
    ...mapFilters(['titleCase']),
    async do(action) {
      if (this.currentData) {
        this.isLoading = true;
        await this.$refs['tree'][action](this.currentData);
        this.isLoading = false;
      }
    },
    getId(node) {
      return node.id;
    },
    onClickNode(evt) {
      this.currentData = evt.element;
      this.onEvent('clickedNode', evt);
    },
    onEvent(eventName, data) {
      this.events.push({ eventName, data: data.data });
    },
    addDisease() {
      this.$store.dispatch('diseaseStore/addDisease', {
        type: this.diseaseType,
        diseaseName: this.currentData.data.name,
      });
    },
    removeDisease(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: this.diseaseType,
        diseaseIndex: index,
      });
    },
    expandNodes() {
      this.$refs.tree.collapseAllChild();
      setTimeout(() => {
        this.$refs.tree.expandParticularNode(this.tracePathArr);
      }, 450);
    },
    expandSignlePath(index) {
      this.$refs.tree.collapseAllChild();
      setTimeout(() => {
        this.$refs.tree.expandParticularNode([this.tracePathArr[index]]);
      }, 450);
    },
    getTracePathArr() {
      this.tracePathArr = getMatchedNodeArr(this.treeData, this.targetNode);
      this.tracePathInfoArr = [];
      this.tracePathArr.forEach((path, pIndex) => {
        let tempObj = this.treeData.children;
        let tempPathInfoArr = [];
        path.forEach((childIndex, index) => {
          if (index === 0) {
            tempPathInfoArr[0] = this.treeData.name;
          } else {
            let targetObj = tempObj[childIndex];
            tempPathInfoArr.push(targetObj.name);
            tempObj = targetObj.children;
          }
        });
        this.tracePathInfoArr[pIndex] = tempPathInfoArr;
      });
    },
    treeCanvasMouseDown() {
      console.log('mouse down');
      this.grabbing = true;
    },
  },
  computed: {
    diseaseSearch() {
      return this.$store.getters['diseaseStore/getDisease'][this.diseaseType];
    },
  },
};
</script>

<style lang="scss"></style>
