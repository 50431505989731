/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

let poolId, clientId;
if (window.location.host == 'demo.dev.em-fact.com') {
  poolId = 'us-east-1_AHmT3TbNO';
  clientId = '48dmgqm30p6v17777h1rakgvlv';
} else if (window.location.host == 'demo.int.em-fact.com' || window.location.host == 'emfact.net' || window.location.host == 'em-fact.com') {
  poolId = 'us-east-1_K1YAD22yv';
  clientId = '7uoi2b30k4abeit2budbpj3dnb';
}  else if (window.location.host == 'demo.uat.em-fact.com' || window.location.host == 'demo.stage.em-fact.com') {
  poolId = 'us-east-1_fvKf0U6q7';
  clientId = '5lpen2a2nm2g4ponu6rhi8b60c';
} else if (window.location.host == 'localhost:8080') {
  poolId = 'us-east-1_AHmT3TbNO';
  clientId = '48dmgqm30p6v17777h1rakgvlv';
}

const awsmobile = {
  aws_project_region: 'us-east-1',

  /* aws_cognito_identity_pool_id:
    'us-east-2:20b5a685-381f-4867-b247-879579db48a0', */

  aws_cognito_region: 'us-east-1',

  aws_user_pools_id: poolId,
  aws_user_pools_web_client_id: clientId,

  oauth: {
    name: 'Google',
    domain: 'emfact-dev.auth.us-east-1.amazoncognito.com', //may need to dynamically inject
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: window.location.origin, //no end "/" on aws, !important 
    redirectSignOut: window.location.origin, //no end "/" on aws, !important
    responseType: 'code'
  },
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};


/* const awsmobile = {
  "aws_project_region": "us-east-2",
  "aws_cognito_identity_pool_id": "us-east-2:20b5a685-381f-4867-b247-879579db48a0",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_f3hQHrCDE",
  "aws_user_pools_web_client_id": "j7el754q8gvpd8p68q8g5vd7v",
  oauth: {
    "domain": "oauthdemo1138748.auth.us-east-1.amazoncognito.com",
    "scope": ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
    "redirectSignIn": "http://localhost:3000",
    "redirectSignOut": "http://localhost:3000",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
} */


export default awsmobile;
