<template>
  <div>
    <v-row>
      <template v-for="(value, key) in biomarkers">
        <v-col v-if="key.includes('multiple')" cols="12" lg="6" :key="key" class="py-0">
          <div>
            <btn-group-round
              :items="getBiomarkerBtnLabel(key)"
              :label="getBiomarkerLabel(key)"
              v-model="biomarkers[key]"
            />
          </div>
        </v-col>
      </template>
    </v-row>

    <v-row>
      <v-col col="12">
        <v-autocomplete
          id="bio-search"
          :items="biomarkerData"
          label="Biomarker Search"
          multiple clearable chips small-chips return-object
          :loading="loadingBiomarkers"
          v-model="biomarkers.biomarkersSelected"
          item-text="biomarker"
          item-value="code"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.biomarker }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import BtnGroupRound from '@/components/BtnGroupRound.vue';
//import biomarkerData from '@/mockData/biomarker.json'
//import { defaultBiomarkers } from '@/store/initState';

export default {
  name: 'BiomarkersDialogHome',
  components: {
    BtnGroupRound,
  },
  props: {
    biomarkersDialog: {
      default: false,
    },
    value: Array,
  },
  data() {
    return {
      //biomarkerData: [],
      biomarkers: {},
      loadingBiomarkers: false,
      defaultBiomarkers: null
    };
  },
  created() {
    this.defaultBiomarkers = cloneDeep(this.$store.getters['criteriaStore/getDefaultBiomarkers'])
    this.biomarkers = { ...this.$store.getters['criteriaStore/getBiomarkers'] };
  },
  methods: {
    remove(item) {
      const index = this.biomarkers.biomarkersSelected.indexOf(item);
      if (index >= 0) {
        this.biomarkers.biomarkersSelected.splice(index, 1);
      } else {
        this.biomarkers.biomarkersSelected.splice(index, 0);
      }
    },
    reset() {
      this.biomarkers = cloneDeep(this.defaultBiomarkers);
    },
    setDemo(data) {
      this.biomarkers = {
        ...this.biomarkers,
        ...data,
      };
    },
    getBiomarkerLabel(key) {
      return this.biomarkersMultipleInfo[key].label
    },
    getBiomarkerBtnLabel(key) {
      return this.biomarkersMultipleInfo[key]['btn-label']
    }
  },
  computed: {
    biomarkersMultipleInfo() {
      return this.$store.getters['criteriaStore/getBiomarkersMultipleInfo']
    },
    biomarkerData() {
      return this.$store.getters['criteriaStore/getBiomarkersData'];
    },
  },
  watch: {
    biomarkers: {
      deep: true,
      handler() {
        this.$store.dispatch('criteriaStore/updateBiomarkers', this.biomarkers);
      },
    },
  },
};
</script>

<style lang="scss"></style>
