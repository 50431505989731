<template>
  <div id="swagger-container">
    <div>
      <div id="swagger-login">
        <p class="text-h4 pt-8">To use API, token is required. Sign in to get a token. </p>
        <v-form data-test="login-form" ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" lg="4">
              <v-text-field id="username" data-test="username" v-model="username" 
                :rules="usernameRules" label="Username*" 
                hide-details outlined dense required v-tf-focus="username" ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field id="password" data-test="password" v-model="password" 
                :rules="passwordRules" type="password" hide-details label="Password*" 
                outlined dense required v-tf-focus="password" ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="d-flex justify-start mt-3">
                <v-btn data-test="login" color="primary" :disabled="!valid" @click="login" >SIGN IN</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col>
            <p v-if="errorMsg" class="error--text v-messages mt-0">
              {{ errorMsg }}
            </p>
            <div id="token-container" v-if="token">
              <div id="token">
                {{ token }}
              </div>
              <button v-if="!keyCopied" class="copy-to-clipboard" @click="copyApiKey()">
                <v-icon size="24" class="white--text text--darken-1" aria-label="copy" role="img" 
                  aria-hidden="false" >mdi-content-copy</v-icon >
              </button>
              <button v-if="keyCopied" class="copy-to-clipboard white--text" disabled>
                Copied
              </button>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div ref="swagger" class="swagger-page"></div>
    <!-- <v-snackbar v-model="snackbar" top>
      Token Copied
    </v-snackbar> -->
  </div>
</template>

<script>
import swaggerMockData from '@/assets/data/emfact.json';
import { Auth } from 'aws-amplify';

export default {
  name: 'SwaggerPage',
  data() {
    return {
      valid: false,
      username: '',
      password: '',
      errorMsg: '',
      token: '',
      snackbar: true,
      keyCopied: false,
      copyTimeout: null,
      usernameRules: [
        (v) => !!v || 'Username is required',
        //v => (v && v.length <= 10) || 'Username must be less than 10 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        //v => (v && v.length <= 10) || 'Password must be less than 10 characters',
      ],
      loading: true,
    };
  },
  async mounted() {
    try {
      const swaggerUI = await getScript('/js/swagger-ui-bundle.js');
      const swaggerCSS = await getStyle('/css/swagger-ui.css');
      const swaggerPreset = await getScript(
        '/js/swagger-ui-standalone-preset.js'
      );
      //await getSwaggerScripts();
      this.$nextTick(this.generateUI);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`swagger errored with: ${e}`);
    }
  },
  methods: {
    async generateUI() {
      try {
        // eslint-disable-next-line
        SwaggerUIBundle({
          spec: swaggerMockData,
          domNode: this.$refs.swagger,
          // eslint-disable-next-line
          presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset],
          // eslint-disable-next-line
          plugins: [SwaggerUIBundle.plugins.DownloadUrl],
          layout: 'StandaloneLayout',
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    login() {
      this.errorMsg = '';
      Auth.signIn(this.username, this.password)
        .then((user) => {
          this.errorMsg = '';
          this.token = user.signInUserSession.idToken.jwtToken;
        })
        .catch((e) => {
          //alert(e.message)
          this.errorMsg = e.message;
        });
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.token);
      this.keyCopied = true;
      if (this.copyTimeout) {
          clearTimeout(this.copyTimeout);
      }
      this.copyTimeout = setTimeout(() => {
        this.copyTimeout = null;
        this.keyCopied = false;
      }, 5000);
    },
  },
};

// swagger utils
/* const swaggerUI = '/js/swagger-ui-bundle.js';
const swaggerPreset = '/js/swagger-ui-standalone-preset.js';
const swaggerCSS = '/css/swagger-ui.css'; */

async function getSwaggerScripts() {
  const swaggerUI = await getScript('/js/swagger-ui-bundle.js');
  const swaggerCSS = await getStyle('/css/swagger-ui.css');
  const swaggerPreset = await getScript('/js/swagger-ui-standalone-preset.js');
  /* return new Promise((resolve) => {
    getScript(swaggerUI).then(() => {
      getStyle(swaggerCSS).then(() => {
        getScript(swaggerPreset).then(resolve);
      });
    });
  }); */
}
async function getScript(src) {
  console.log('script src', src);
  return new Promise((resolve) => {
    let script = document.createElement('script');
    script.onload = () => {
      resolve();
    };
    script.async = true;
    script.src = src;
    document.head.appendChild(script);
  });
}
async function getStyle(src) {
  return new Promise((resolve) => {
    let script = document.createElement('link');
    script.onload = () => {
      resolve();
    };
    script.async = true;
    script.href = src;
    script.type = 'text/css';
    script.rel = 'stylesheet';
    document.head.appendChild(script);
  });
}
</script>

<style lang="scss">
.swagger-ui {
  .information-container {
    background-color: #f0f0f0 !important;
  }
  button:focus {
    outline: none !important;
  }
}
#swagger-container .swagger-ui .info {
  background-color: #f0f0f0 !important;
}
.swagger-ui .info .title {
  color: #3b4151;
  font-family: sans-serif;
  font-size: 36px !important;
  margin: 0;
}
#token-container {
  position: relative;
  .copy-to-clipboard {
    position: absolute;
    right: 5px;
    bottom: 10px;
  }
  #token {
    font-size: 12px;
    overflow-x: hidden;
    //white-space: nowrap;
    padding: 0.5em 0.5em 3em;
    background: rgb(51, 51, 51);
    border-radius: 3px;
    color: #cccccc;
  }
}
.topbar {
  display: none;
}
</style>
