<template>
  <footer class="footer">
    <v-container fluid class="well gray">
      <v-row>
        <v-col cols="12" sm="2" class="pt-1">
          <img src="/img/essexwhite.png" width="74" alt="essex logo">
        </v-col>
        <v-col cols="12" sm="4" offset-sm="1" offset-md="1" class="pt-0">
          Contact Us <br />
          <a href="tel:+1(301)760-7527">T: 301.760.7527</a><br />
          <a href="mailto:emfact@essexmanagement.com">Email Us</a><br />
          <a href="https://www.google.com/maps/place/Rockville,+MD/@39.0869125,-77.2161887,12z/data=!3m1!4b1!4m5!3m4!1s0x89b62a2cfee39115:0xeca5be10d9c9df24!8m2!3d39.0839973!4d-77.1527578" target="_blank">Rockville, MD</a>
        </v-col>
        <v-col cols="12" sm="4" class="pt-0">
          <span>Follow</span><br />
          <!-- <a href="https://www.linkedin.com/company/essex-management-llc" target="_blank" class="btn-social ml-4 mr-4 my-2">
            <i class="fab fa-linkedin-in"></i>
          </a> -->
          <a href="https://www.linkedin.com/company/essex-management-llc" target="_blank" class="btn-social ml-4 mr-4 my-2">
            <img src="/img/linkedin_logo.png" width="32" alt="linkedin logo">
          </a>
          <a href="https://twitter.com/EssexMgmt" target="_blank" class="btn-social mr-4 my-2">
            <img src="/img/twitter_logo.png" width="32" alt="twitter logo">
          </a>
          <!-- <a href="https://twitter.com/EssexMgmt" target="_blank" class="btn-social ny-4">
            <i class="fab fa-twitter"></i>
          </a> -->
          <br />
          <span class="mt-0 d-block" @click.shift="resetLoginCount">Powered by NCI's CTRP Clinical Trials Search API.</span>
          <span class="mt-0 d-block">© 2021-2023 Essex Management. All Rights Reserved.</span>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'vfooter',
  data() {
    return {
    };
  },
  mounted() {
    this.buildTime = process.env.VUE_APP_BUILD_TIME;
  },
  methods: {
    async resetLoginCount(e) {
      let user = this.$store.getters['authStore/getUser'];
      await Auth.deleteUserAttributes(user, ['custom:login_count']);
    }
  }
};
</script>

<style lang="scss">
/* footer */
#app.v-application {
  .footer {
    .well {
      &.gray {
        background: gray;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        a {
          font-size: 8pt;
          color: #FFFFFF;
          text-decoration: none;
          &:hover {text-decoration: underline;}
        }
      }
      min-height: 20px;
      padding: 19px;
      margin-bottom: 0;
      background-color: #f5f5f5;
      //border: 1px solid #e3e3e3;
      //border-radius: 4px;
      //-webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
      //box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
      .footer-content {
        font-size: 18pt;
        font-weight: bold;
        padding: 0 15px;
        text-align-last: center;
        span {
          font-size:25pt; 
          color:#06abc0
        }
      }
    }
    .btn-social {
      display: inline-block;
      width: 32px; height: 32px;
      border-radius: 50%;
      text-align: center;
      overflow: hidden;
      background-color: #FFFFFF;
      &:hover {
        .fab {
          color: #333;
        }
      }
    }
    .fab {
      display: block;
      line-height: 34px;
      margin: auto;
      font-size: 20px;
      color: gray;
    }
    @media only screen and (max-width: 959px) {
      text-align: center;
    }
  }
}
</style>
