import { cloneDeep } from 'lodash';
import fetchService from '@/services/fetchService';

export const defaultFiltersSelections = {
  participantAttr: ['disease'],
  searchType: 0,
  phases: ['II', 'III'],
  vaNIH: [],
  studySource: []
}

export default {
  state: {
    filterSelections: { ...defaultFiltersSelections },
    trialsHeader: [],
    trials: [],
    filteredTrials: [],
    matchedTrials: [],
    //starSelectedArr: Array(11).fill('F'), 
    eyeSelectedArr: Array(11).fill('F'),
    cancerCenterData: [],
    leadOrgsData: [],
    participantAttrData: [],
    trialsIDforSelectedOrg: [],
    trialsIDforSelectedLeadOrg: [],
    trialsIDforDistance: [],
    showHideStarEyeStatus: 1,
    loadingNCTId: false,
    distance: 0,
    geo: { lat: '', lon: '' },
    geoArr: [],
    filterCancerCenter: [],
    filterLeadOrg: [],
    starNCTArr: [],
    CCDIStatus: '', //CCDI
    shouldFilterByCCDI: 'N'
  },
  getters: {
    getCCDIStatus: state => state.CCDIStatus,
    getShouldFilterByCCDI: state => state.shouldFilterByCCDI,
    getFilterSelections: state => state.filterSelections,
    getCancerCenterData: state => state.cancerCenterData,
    getLeadOrgsData: state => state.leadOrgsData,
    getParticipantAttrData: state => state.participantAttrData,
    getLoadingNCTId: state => state.loadingNCTId,
    getMatchedTrials: state => state.matchedTrials,
    getMatchedWithoutHiddenTrials: state => {
      return state.matchedTrials.filter((trial, index) => {
        return state.eyeSelectedArr[trial.id - 1] == 'F'
      })
    },
    getStarSelectedTrials: state => {
      //return state.trials.filter((trial, index) => {
      return state.matchedTrials.filter((trial, index) => {
        return trial.starred == true
      })
    },
    getTrials: state => state.trials,
    getStarredTrials: state => {
      return state.trials.filter(trial => trial.starred == true)
    },
    getFilteredTrials: state => state.filteredTrials,
    getTrialsHeader: state => {
      return state.trialsHeader
    },
    getNumberOfStarSelected: state => state.starNCTArr.length,
    //getNumberOfStarNCTId: state => state.starNCTArr.length,
    getNumberOfEyeSelected: state => state.eyeSelectedArr.filter(item => item == 'T').length,
    //getStarStatus: state => id => state.starSelectedArr[id],
    //getStarStatus: state => id => state.trials[id].starred,
    getEyeStatus: state => id => state.eyeSelectedArr[id],
  },
  mutations: {
    UPDATE_CANCER_CENTER_DATA(state, data) {
      state.cancerCenterData = data;
    },
    UPDATE_LEAD_ORGS_DATA(state, data) {
      state.leadOrgsData = data;
    },
    UPDATE_TRIALS(state, trialsData) {
      //state.starSelectedArr = Array(trialsData.id.length).fill('F');
      state.eyeSelectedArr = Array(trialsData.id.length).fill('F');
      state.trials = convertDataFormatFromArrToObj(trialsData, state);
      state.filteredTrials = [...state.trials];
    },
    UPDATE_TRIALS_HEADER(state, updatedHeader) {
      state.trialsHeader.forEach(header => {
        if (!header.value.includes('text')) {
          if (updatedHeader.indexOf(header.text) !== -1) {
            header.show = true;
          } else {
            header.show = false;
          }
        }
      });
    },
    TOGGLE_CRITERIA(state, shown) {
      state.trialsHeader.forEach(header => {
        if (header.value.includes('_text') || header.value == 'min_age_in_years' || header.value == 'max_age_in_years') {
          header.show = shown;
        }
      });
    },
    SET_INIT_STARRED_TRIALS(state, starredTrials) {
      let finalStarredTrials = starredTrials.map(trial => {
        return {
          ...trial,
          starred: true
        }
      })
      starredTrials.forEach(trial => {
        state.starNCTArr.push({ nct_id: trial.nct_id });
      })
      state.trials = finalStarredTrials;
    },
    TOGGLE_STAR_STATUS(state, item) {
      item.starred = item.starred == false ? true : false;
      let index = state.trials.findIndex(trial => {
        return trial.nct_id == item.nct_id
      })
      state.trials.splice(index, 1, item);
      if (item.starred == true) {
        state.starNCTArr = [...state.starNCTArr, { nct_id: item.nct_id }];
      } else {
        let index = state.starNCTArr.findIndex(ele => ele.nct_id == item.nct_id);
        state.starNCTArr.splice(index, 1);
      }
    },
    TOGGLE_EYE_STATUS(state, id) {
      let value = state.eyeSelectedArr[id - 1] == 'F' ? 'T' : 'F';
      state.eyeSelectedArr.splice(id - 1, 1, value);
      if ((value == 'T' && state.showHideStarEyeStatus !== 0) || (value == 'F' && state.showHideStarEyeStatus == 3)) {
        state.filteredTrials = state.filteredTrials.filter(trial => {
          return trial.id !== id
        });
      }
    },
    SHOW_HIDE_STAR_EYE_TRIALS(state, ref) {
      state.showHideStarEyeStatus = ref;
      //if ref == 0, show all trials, no need to filter
      if (ref == 0) {
        state.filteredTrials = state.matchedTrials;
      } else if (ref == 1) {
        state.filteredTrials = state.matchedTrials.filter(trial => {
          return state.eyeSelectedArr[trial.id - 1] == 'F' ? true : false;
        })
      } else if (ref == 2) {
        state.filteredTrials = state.matchedTrials.filter(trial => {
          let starNctIdArr = state.starNCTArr.map(obj => {
            return obj.nct_id
          })
          return starNctIdArr.indexOf(trial.nct_id) == -1 ? false : true
          //return state.trials[trial.id - 1].starred == true ? true : false;
        })
      } else {
        state.filteredTrials = state.matchedTrials.filter(trial => {
          return state.eyeSelectedArr[trial.id - 1] == 'T' ? true : false;
        })
      }
    },
    UPDATE_FILTER_SELECTIONS(state, filterSelections) {
      state.filterSelections = filterSelections
    },
    UPDATE_PARTICIPANT_ATTR(state, participantAttr) {
      state.filterSelections.participantAttr = participantAttr
    },
    UPDATE_FILTERED_TRIALS(state, payload = {}) {
      state.loadingNCTId = true;
      let tempFilteredTrials = [...state.trials]
      //for CCDI group
      if (state.CCDIStatus == 'CCDI' && state.shouldFilterByCCDI == 'Y') {
        tempFilteredTrials = tempFilteredTrials.filter(trial => {
          return trial.is_filtered_trial === true;
        })
      }

      //for participant attribute
      state.filterSelections.participantAttr.forEach(fieldName => {
        //for disease
        if (fieldName === 'disease') {
          if (state.filterSelections.searchType == 0) {
            //focuse match -- lead_disease_matches
            tempFilteredTrials = tempFilteredTrials.filter(trial => {
              return trial.lead_disease_matches == true
            })
          } else {
            //genaral match -- disease_matches
            tempFilteredTrials = tempFilteredTrials.filter(trial => {
              return trial.disease_matches == true
            })
          }
        } else {
          const isExc = getExcFromFieldName(fieldName, state.trialsHeader)
          if (isExc) {
            tempFilteredTrials = tempFilteredTrials.filter(trial => {
              return trial[fieldName] !== true
            })
          } else {
            tempFilteredTrials = tempFilteredTrials.filter(trial => {
              return trial[fieldName] !== false
            })
          }
        }
      })
      ////console.log('1111111111111', tempFilteredTrials)
      //for phase
      if (state.filterSelections.phases.length > 0) {
        tempFilteredTrials = tempFilteredTrials.filter(trial => {
          return hasCommonElements(trial.phase.split('_'), state.filterSelections.phases)
        })
      }
      ////console.log('22222222222', tempFilteredTrials)
      //va nih_cc
      if (state.filterSelections.vaNIH.length > 0) {
        state.filterSelections.vaNIH.forEach(fieldName => {
          tempFilteredTrials = tempFilteredTrials.filter(trial => {
            return trial[fieldName] !== false
          })
        })
      }
      ////console.log('333333333333', tempFilteredTrials)
      //study source
      if (state.filterSelections.studySource.length > 0) {
        tempFilteredTrials = tempFilteredTrials.filter(trial => {
          return state.filterSelections.studySource.indexOf(trial.study_source) !== -1
        })
      }
      ////console.log('444444444444', tempFilteredTrials)
      //cancer center
      if (state.trialsIDforSelectedOrg.length >= 0 && state.filterCancerCenter.length > 0) {
        tempFilteredTrials = tempFilteredTrials.filter(trial => {
          return state.trialsIDforSelectedOrg.indexOf(trial.nct_id) !== -1
        })
      }
      //lead org
      //if (state.trialsIDforSelectedLeadOrg.length >= 0 && state.filterLeadOrg.length > 0) {
      if (state.filterLeadOrg.length > 0) {
        tempFilteredTrials = tempFilteredTrials.filter(trial => {
          return state.filterLeadOrg.indexOf(trial.lead_org) !== -1
        })
      }
      ////console.log('5555555555555', tempFilteredTrials)
      //distance
      if (state.trialsIDforDistance.length > 0 && state.distance > 0 && state.geo.lat !== '') {
        tempFilteredTrials = tempFilteredTrials.filter(trial => {
          return state.trialsIDforDistance.indexOf(trial.nct_id) !== -1
        })
      }
      if (state.trialsIDforDistance.length > 0 && state.distance > 0 && state.geoArr.length > 0) {
        tempFilteredTrials = tempFilteredTrials.filter(trial => {
          return state.trialsIDforDistance.indexOf(trial.nct_id) !== -1
        })
      }
      ////console.log('6666666666666', tempFilteredTrials)
      state.matchedTrials = tempFilteredTrials;
      state.filteredTrials = tempFilteredTrials;
      setTimeout(() => {
        state.loadingNCTId = false;
      }, 150)
    },
    UPDATE_COL_INFO(state, payload) {
      state.trialsHeader = buildColInfo(payload);
      state.participantAttrData = buildParticipantAttrData(payload);
    },
    RESET_DISTANCE(state) {
      state.trialsIDforDistance.length = [];
      state.geo = { lat: '', lon: '' }
    },
    UPDATE_CCDI_STATUS(state, status) {
      state.CCDIStatus = status
    },
    UPDATE_SHOULD_FILTER_CCDI_STATUS(state, YorN) {
      state.shouldFilterByCCDI = YorN
    }
  },
  actions: {
    fetchColInfo({ commit }) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('column_info')
          .then(response => {
            commit('UPDATE_COL_INFO', response);
            resolve(response);
          })
          .catch(error => {
            ////console.log('error', error);
            reject(error);
          })
      })
    },
    fetchCancerCenter({ commit }) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('org_families')
          .then(response => {
            commit('UPDATE_CANCER_CENTER_DATA', response);
            resolve(response);
          })
          .catch(error => {
            //console.log('error', error);
            reject(error);
          })
      })
    },
    fetchLeadOrgs({ commit }) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('lead_orgs')
          .then(response => {
            commit('UPDATE_LEAD_ORGS_DATA', response);
            resolve(response);
          })
          .catch(error => {
            //console.log('error', error);
            reject(error);
          })
      })
    },
    fetchTrialsForCancerCenter({ commit, state }, payload) {
      state.filterCancerCenter = payload.cancer_centers;
      if (payload.cancer_centers.length !== 0) {
        let finalTargetNctId = []
        payload.cancer_centers.forEach(cName => {
          let targetNctId = state.cancerCenterData.find(item => item.name == cName).nct_id.split(',');
          finalTargetNctId.push(...targetNctId);
        })

        state.trialsIDforSelectedOrg = finalTargetNctId.filter(function (item, pos) {
          return finalTargetNctId.indexOf(item) == pos;
        })
        commit('UPDATE_FILTERED_TRIALS')
      } else {
        state.trialsIDforSelectedOrg = [];
        commit('UPDATE_FILTERED_TRIALS');
      }
    },
    fetchTrialsForLeadOrg({ commit, state }, payload) {
      state.filterLeadOrg = payload.lead_orgs;
      commit('UPDATE_FILTERED_TRIALS');
    },
    fetchTrialsForDistance({ commit, state }, params) {
      state.distance = params.distance;
      state.geo = params.geo;
      if (params.distance <= 0 || params.distance == '' || isNaN(params.distance)) {
        state.trialsIDforDistance = [];
        commit('UPDATE_FILTERED_TRIALS');
      } else {
        let payload = {
          "latitude": params.geo.lat,
          "longitude": params.geo.lon,
          "distance": params.distance
        }
        state.loadingNCTId = true;
        return new Promise((resolve, reject) => {
          ////console.log('1/4')
          fetchService.postDataFiles('studies_for_lat_lon_distance', payload)
            .then(response => {
              state.trialsIDforDistance = response;
              state.loadingNCTId = false;
              commit('UPDATE_FILTERED_TRIALS')
              resolve(response)
            })
            .catch(error => {
              //console.log('error!', error)
              reject(error)
            })
        })

      }
    },
    fetchTrialsForDistanceArr({ commit, state }, params) {
      state.distance = params.distance;
      state.geoArr = params.geoArr;
      if (params.distance <= 0 || params.distance == '' || params.distance == null || isNaN(params.distance)) {
        state.trialsIDforDistance = [];
        commit('UPDATE_FILTERED_TRIALS');
      } else {
        let payload = {
          coordinates: params.geoArr.filter(geo => geo.lat !== '').map(geo => {
            return {
              "latitude": geo.lat,
              "longitude": geo.lon,
              "distance": params.distance
            }
          })
        }

        state.loadingNCTId = true;
        return new Promise((resolve, reject) => {
          ////console.log('2/4')
          fetchService.postDataFiles('studies_for_lat_lon_distance', payload)
            .then(response => {
              state.trialsIDforDistance = response;
              state.loadingNCTId = false;
              commit('UPDATE_FILTERED_TRIALS')
              resolve(response)
            })
            .catch(error => {
              //console.log('error!', error)
              reject(error)
            })
        })

      }
    },
    fetchTrialsForDistanceNoUpdateArr({ state }, params) {
      state.geoArr = params.geoArr;
      if (state.distance <= 0 || state.distance == '' || state.distance == null || isNaN(state.distance) || state.geoArr.length == 0) {
        state.trialsIDforDistance = [];
      } else {
        let payload = {
          coordinates: params.geoArr.filter(geo => geo.lat !== '').map(geo => {
            return {
              "latitude": geo.lat,
              "longitude": geo.lon,
              "distance": state.distance
            }
          })
        }

        return new Promise((resolve, reject) => {
          ////console.log('3/4')
          fetchService.postDataFiles('studies_for_lat_lon_distance', payload)
            .then(response => {
              state.trialsIDforDistance = response;
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    },
    fetchTrialsForDistanceNoUpdate({ state }, params) {
      state.geo = params.geo;
      if (state.distance <= 0 || state.distance == '') {
        state.trialsIDforDistance = [];
      } else {
        let payload = {
          "latitude": params.geo.lat,
          "longitude": params.geo.lon,
          "distance": state.distance
        }
        return new Promise((resolve, reject) => {
          ////console.log('4/4')
          fetchService.postDataFiles('studies_for_lat_lon_distance', payload)
            .then(response => {
              state.trialsIDforDistance = response;
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    },
    updateZipcodeFetchTrialsForDistance({ dispatch, state }, geo) {
      let params = {
        geo,
        distance: state.distance
      }
      dispatch('fetchTrialsForDistance', params)
    },
    updateTrialsHeader({ commit }, updatedHeader) {
      commit(`UPDATE_TRIALS_HEADER`, updatedHeader)
    },
    findMatches({ commit, dispatch, state }, payload) {
      let firstTimeFindSearch = payload.firstTimeFindSearch;
      let initParticipantsAttr = payload.initParticipantsAttr;
      state.loadingNCTId = true;
      delete payload.firstTimeFindSearch;
      delete payload.initParticipantsAttr;
      return new Promise((resolve, reject) => {
        fetchService.postDataFiles('search_and_match', payload)
          .then(res => {
            state.loadingNCTId = false;
            commit('UPDATE_TRIALS', res);
            if (firstTimeFindSearch) {
              commit('UPDATE_PARTICIPANT_ATTR', initParticipantsAttr)
            } else {
              dispatch('updateFilterSelections', state.filterSelections)
            }

            resolve(res);
          })
      })
    },
    fetchStarredTrials({ commit, state }) {
      return new Promise((resolve, reject) => {
        fetchService.getDataFiles('get_starred_data')
          .then(response => {
            commit('SET_INIT_STARRED_TRIALS', response)
            resolve(response)
          })
          .catch(error => {
            //console.log('error', error)
            reject(error)
          })
      })
    },
    toggleCriteria({ commit }, shown) {
      commit('TOGGLE_CRITERIA', shown)
    },
    toggleStarStatus({ commit, dispatch, state }, item) {
      commit('TOGGLE_STAR_STATUS', item);
      commit('SHOW_HIDE_STAR_EYE_TRIALS', state.showHideStarEyeStatus);
      dispatch('postStarredTrials')
    },
    toggleEyeStatus({ commit }, id) {
      commit('TOGGLE_EYE_STATUS', id)
    },
    showHideStarHideTrials({ commit }, ref) {
      commit('SHOW_HIDE_STAR_EYE_TRIALS', ref)
    },
    updateFilterSelections({ commit, state }, filterSelections) {
      commit('UPDATE_FILTER_SELECTIONS', filterSelections);
      commit('UPDATE_FILTERED_TRIALS');
      commit('SHOW_HIDE_STAR_EYE_TRIALS', state.showHideStarEyeStatus)
    },
    resetDistance({ commit }) {
      commit('RESET_DISTANCE')
    },
    postStarredTrials({ state }) {
      ////console.log('post_starred_trials', state.starNCTArr)
      return new Promise((resolve, reject) => {
        fetchService.postDataFiles('post_starred_trials', state.starNCTArr)
          .then(res => {
            //console.log('postStarredTrials', res)
            resolve(res);
          })
      })
    },
    updateCCDIStatus({commit}, status) {
      commit('UPDATE_CCDI_STATUS', status);
      commit('UPDATE_FILTERED_TRIALS');
    }
  }
}

function convertDataFormatFromArrToObj(data, state) {
  let trialsData = [];
  let rows = data.nct_id.length;
  state.trialsLoaded = true;
  state.starNCTArr = [];
  for (let i = 0; i < rows; i++) {
    let rowObj = {}
    for (const property in data) {
      rowObj[property] = data[property][i]
      if (property == 'starred' && data[property][i] == true) {
        state.starNCTArr.push({ nct_id: data['nct_id'][i] })
      }
    }
    trialsData.push(rowObj)
  }
  trialsData = trialsData.sort((a, b) => {
    return b.score - a.score
  })
  return trialsData;
  ////console.log(JSON.stringify(trialsData))
}

function hasCommonElements(arr1, arr2) {
  //arr2 filter selection, arr1 is trials data
  if (arr2.includes('I') && arr1.includes('O')) {
    return true
  } else {
    return arr1.some(item => arr2.includes(item))
  }
}

function buildColInfo(data) {
  let columnInfoObjArr = [];
  let widthInfo = {
    nct_id: 100,
    brief_title: 300,
    phase: 100,
    disease_names_lead: 250,
    disease_names: 300,
  }
  let refCol = ['star', 'hide', 'nct_id', 'brief_title'];
  let width100 = ['nct_id', 'min_age_in_years', 'max_age_in_years', 'gender_criteria'];
  let width130 = ['va_matches', 'nih_cc_matches', 'study_source'];
  let centerArr = ['phase', 'disease_names_lead', 'study_source'];
  let pre = [
    { text: '', filterLabel: 'ref', value: 'id', sortable: false, width: 40, small_font: false, show: true, "tooltip": false },
    { text: 'Hide', filterLabel: 'ref', value: 'hide', width: 40, sortable: false, small_font: false, show: true, "tooltip": false },
    //{text: 'Star', filterLabel: 'ref', value: 'star', align: 'center', width: 40, sortable: true, small_font: false, show: true, "tooltip": false}
  ]
  data.forEach((item, index) => {
    if (item.value !== 'id') {
      columnInfoObjArr.push({
        ...item,
        show: !item.hidden //init show column or not
      })
      if (item.hidden == true || refCol.indexOf(item.value) !== -1) {
        columnInfoObjArr[index - 1].filterLabel = 'ref';
      }

      //for width
      if (item.value.includes('matches') || width100.indexOf(item.value) !== -1) {
        columnInfoObjArr[index - 1].width = 100;
        columnInfoObjArr[index - 1].align = 'center';
      } else if (item.value == 'starred' || item.value == 'hide') {
        columnInfoObjArr[index - 1].filterLabel = 'ref';
        columnInfoObjArr[index - 1].width = 40;
        columnInfoObjArr[index - 1].align = 'center';
        columnInfoObjArr[index - 1].sortable = true;
      } else if (item.value.includes('_text')) {
        columnInfoObjArr[index - 1].width = 300;
        columnInfoObjArr[index - 1].align = 'center'
      } else if (width130.indexOf(item.value) !== -1) {
        columnInfoObjArr[index - 1].width = 130;
      } else {
        if (centerArr.indexOf(item.value) !== -1) {
          columnInfoObjArr[index - 1].align = 'center'
        } else {
          columnInfoObjArr[index - 1].align = 'start'
        }
        columnInfoObjArr[index - 1].width = widthInfo[item.value];
      }
    }
  })

  columnInfoObjArr = [...pre, ...columnInfoObjArr]

  return columnInfoObjArr
}

function buildParticipantAttrData(data) {
  let tempAttr = [];
  data.forEach(item => {
    if (item.is_participant_attribute == true && item.value !== 'disease_matches') {
      tempAttr.push(
        { label: item.text, field_name: item.value }
      )
    }
  })
  return [
    { label: 'Diseases', field_name: 'disease' },
    ...tempAttr
  ]
}

function getExcFromFieldName(fieldName, headerInfo) {
  return headerInfo.find(header => {
    return header.value == fieldName
  }).exclusion
}