var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"table-tab-wrapper"}},[_c('div',{staticClass:"ml-md-4 mr-md-4",attrs:{"id":"list-view-progress-wrapper"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingNCTId),expression:"loadingNCTId"}],attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{staticClass:"data-table-wrapper ml-md-4 pr-md-4"},[_c('v-data-table',{attrs:{"id":"scroll-data-table","headers":_vm.activeTrialsHeader,"items":_vm.filterTrials,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{'items-per-page-options':[5, 10, 20, 50, 100, -1]},"search":_vm.searchStr,"custom-sort":_vm.sortEmptyLast,"must-sort":"","fixed-header":""},on:{"pagination":_vm.onPaginationChange,"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([_vm._l((_vm.activeTrialsHeader),function(col){return {key:("item." + (col.value)),fn:function(ref){
var item = ref.item;
var value = ref.value;
var header = ref.header;
return [(header.value == 'hide')?[_c('eye-hidden',{key:col.value,attrs:{"item":item}})]:(header.value == 'starred')?[_c('star',{key:col.value,attrs:{"item":item}})]:(header.value == 'nct_id')?[_c('a',{key:col.value,attrs:{"href":'https://www.cancer.gov/about-cancer/treatment/clinical-trials/search/v?id=' + value + '&r=1',"target":"_blank"}},[_vm._v(_vm._s(value))])]:(header.value.includes('matches'))?_c('span',{key:col.value},[(value !== true && value !== false)?_c('span',{staticClass:"no-icon",staticStyle:{"opacity":"0"},attrs:{"aria-hidden":"true"}},[_vm._v("-")]):(col.exclusion == true)?_c('match-exc',{attrs:{"value":value}}):_c('match',{attrs:{"value":value}})],1):[(header.tooltip == true)?_c('content-with-tooltips',{key:col.value,attrs:{"value":value}}):_c('span',{key:col.value,class:{'small-font': header.small_font}},[_vm._v(_vm._s(value))])]]}}})],null,true)}),_c('v-data-table',{class:{windows: _vm.os === 'windows'},attrs:{"id":"fixed-data-table","headers":_vm.activeTrialsHeader,"items":_vm.filterTrials,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.searchStr,"custom-sort":_vm.sortEmptyLast,"hide-default-footer":"","must-sort":"","fixed-header":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([_vm._l((_vm.activeTrialsHeader),function(col){return {key:("item." + (col.value)),fn:function(ref){
var item = ref.item;
var value = ref.value;
var header = ref.header;
return [(header.value == 'hide')?[_c('eye-hidden',{key:col.value,attrs:{"item":item}})]:(header.value == 'starred')?[_c('star',{key:col.value,attrs:{"item":item}})]:(header.value == 'nct_id')?[_c('a',{key:col.value,attrs:{"href":'https://www.cancer.gov/about-cancer/treatment/clinical-trials/search/v?id=' + value + '&r=1',"target":"_blank"}},[_vm._v(_vm._s(value))])]:[(header.tooltip == true)?_c('content-with-tooltips',{key:col.value,attrs:{"value":value}}):_c('span',{key:col.value,class:{'small-font': header.small_font}},[_vm._v(_vm._s(value))])]]}}})],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }