import axios from 'axios';
import { truthy } from '@/services/CommonService';
import store from '@/store/store'
import { Auth } from 'aws-amplify';
import router from '@/router'

const BASE_URLS = {
  //trials: process.env.VUE_APP_API_URL
  //trials: 'https://205iwh3n9g.execute-api.us-east-1.amazonaws.com/prod/api/v1/'
  //trials: 'https://demo.dev.em-fact.com/api/v1/'
  trials: process.env.VUE_APP_REMOTE === 'true' ? 'https://demo.dev.em-fact.com/api/v1/' : '/api/v1/',
  //trials: 'api/v1/'
};

let defaultQuery = {
  baseURL: BASE_URLS.trials,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  params: {},
  /* paramsSerializer(params) {
    return new URLSearchParams(filterParams(params))
  } */
};
let pdfQuery = {
  baseURL: BASE_URLS.trials,
  headers: { Accept: 'application/pdf', 'Content-Type': 'application/json' },
    'responseType': 'blob',
  params: {}
};

let ignoreReset= ['report', 'studies_for_lat_lon_distance', 'studies_for_cancer_centers', 'studies_for_lead_orgs']

/* axios.interceptors.request.use(
  async config => {
    if (config.url.indexOf('bioappdev') == -1) {
      config.headers['authorization'] = `Bearer ${await router.app.$store.getters['auth/token']}`;
      config.params.client = 'accrual_ui'
    }
    return config;
  },
  error => {
    console.log('interceptor', error);
    Promise.reject(error)
  }
); */
const postDataFiles = async (url, data, retry = 15) => {
  const IDToken = process.env.VUE_APP_SKIP_AUTH === 'true' ? `ABCD` : `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  data.program_group_filter = store.getters['authStore/getSelectedGroup'];

  defaultQuery.headers.Authorization = IDToken;
  pdfQuery.headers.Authorization = IDToken;
  let options = url === 'report' ? {...pdfQuery } : { ...defaultQuery }
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, options)
        .then((res) => {
          if (res.status == 266 && retry > 1) {
            setTimeout(() => {
              resolve(postDataFiles(url, data, retry - 1));
            }, 1000);
          } else if (res.status == 266 && retry <= 1) {
            alert('Database Error! Please try again later.')
          } else {
              if (ignoreReset.indexOf(url) === -1) { //not on ignoreReset list, ie. talk to db, so restart counter
              resetTimeout();
            }
            resolve(res.data);
          }
        })
        .catch((error) => {
          //console.log('error.response', error.response)
          if (error.response.status == 425 && retry > 1) {
            setTimeout(() => {
              //console.log('retry', retry);
              resolve(postDataFiles(url, data, retry - 1));
              console.log('error.message', error.message);
            }, 1000);
          } else if (error.response.status == 401) { //refresh token
            Auth.currentSession()
              .then(cdata => {
                store.commit('authStore/UPDATE_TOKEN', cdata.idToken.jwtToken);
                resolve(postDataFiles(url, data, retry - 1));
              })
          } else if (error.response.status == 500) {
            alert('Database Error! Please try again later.')
            reject(error);
          } else {
            console.log('fetch done with error');
            alert(`${error} please contact emFACT support at emfact@essexmanagement.com `)
            reject(error);
          }
        })
  });
}

const putDataFiles = async (url, data, retry = 15) => {
  const IDToken = process.env.VUE_APP_SKIP_AUTH === 'true' ? `ABCD` : `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

  data.program_group_filter = store.getters['authStore/getSelectedGroup'];
  
  defaultQuery.headers.Authorization = IDToken;
  let options = { ...defaultQuery }
  return new Promise((resolve, reject) => {
    axios
      .put(url, data, options)
        .then((res) => {
          if (res.status == 266 && retry > 1) {
            setTimeout(() => {
              resolve(putDataFiles(url, data, retry - 1));
            }, 1000);
          } else if (res.status == 266 && retry <= 1) {
            alert('Database Error! Please try again later.')
          } else {
            if (ignoreReset.indexOf(url) === -1) { //not on ignoreReset list, ie. talk to db, so restart counter
              resetTimeout();
            }
            resolve(res.data);
          }
        })
        .catch((error) => {
          //console.log('error.response', error.response)
          if (error.response.status == 425 && retry > 1) {
            setTimeout(() => {
              //console.log('retry', retry);
              resolve(putDataFiles(url, data, retry - 1));
              console.log('error.message', error.message);
            }, 1000);
          } else if (error.response.status == 401) { //refresh token
            //console.log('refresh token')
            Auth.currentSession()
              .then(cdata => {
                store.commit('authStore/UPDATE_TOKEN', cdata.idToken.jwtToken);
                resolve(putDataFiles(url, data, retry - 1));
              })
          } else {
            console.log('fetch done with error');
            reject(error);
          }
        })
  });
}

const deleteDataFiles = async (url, data, retry = 15) => {
  const IDToken = process.env.VUE_APP_SKIP_AUTH === 'true' ? `ABCD` : `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`

  defaultQuery.headers.Authorization = IDToken;
  return new Promise((resolve, reject) => {
    axios
      .delete(url, {data, ...defaultQuery})
        .then((res) => {
          if (res.status == 266 && retry > 1) {
            setTimeout(() => {
              resolve(deleteDataFiles(url, data, retry - 1));
            }, 1000);
          } else if (res.status == 266 && retry <= 1) {
            alert('Database Error! Please try again later.')
          } else {
            if (ignoreReset.indexOf(url) === -1) { //not on ignoreReset list, ie. talk to db, so restart counter
              resetTimeout();
            }
            resolve(res.data);
          }
        })
        .catch((error) => {
          //console.log('error.response', error.response)
          if (error.response.status == 425 && retry > 1) {
            setTimeout(() => {
              //console.log('retry', retry);
              resolve(deleteDataFiles(url, data, retry - 1));
              console.log('error.message', error.message);
            }, 1000);
          } else if (error.response.status == 401) { //refresh token
            //console.log('refresh token')
            Auth.currentSession()
              .then(cdata => {
                store.commit('authStore/UPDATE_TOKEN', cdata.idToken.jwtToken);
                resolve(deleteDataFiles(url, data, retry - 1));
              })
          } else {
            console.log('fetch done with error');
            reject(error);
          }
        })
  });
}

const getDataFiles = async (url, params = {}, retry = 15) => {
  const IDToken = process.env.VUE_APP_SKIP_AUTH === 'true' ? `ABCD` : `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`

  defaultQuery.headers.Authorization = IDToken;
  if (url == 'search_session_data') { //resolve server cache issue
    params.rnd = Math.floor(Math.random() * 100000000);
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, { ...defaultQuery, params })
        .then((res) => {
          if (res.status == 266 && retry > 1) {
            setTimeout(() => {
              resolve(getDataFiles(url, params, retry - 1));
            }, 1000);
          } else if (res.status == 266 && retry <= 1) {
            alert('Database Error! Please try again later.')
          } else {
            if (ignoreReset.indexOf(url) === -1) { //not on ignoreReset list, ie. talk to db, so restart counter
              resetTimeout();
            }
            resolve(res.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 425 && retry > 1) {
            setTimeout(() => {
              //console.log('retry', retry);
              resolve(getDataFiles(url, params, retry - 1));
            }, 1000);
          } else if (error.response.status == 500) {
            alert('Database Error! Please try again later.')
            reject(error);
          } else {
            console.log('fetch done with error');
            alert(`${error} please contact emFACT support at emfact@essexmanagement.com `)
            reject(error);
          }
        })
  });
}

/* export function filterParams(params) {
  for (let key in params) {
    if ({}.hasOwnProperty.call(params, key) && !truthy(params[key])) {
      delete params[key] // delete empty values
    }
  }
  return params
} */

let apiCounter = 3 * 60 * 1000;
//let apiCounter = 20 * 1000;
let apiTimeoutID;

const startTimeout = () => {
  apiTimeoutID = setTimeout(() => {
    if (router.currentRoute.name !== 'Share') {
      postDataFiles('wakeup_db', {}, 0)
    }
  }, apiCounter)
}
const resetTimeout = () => {
  //console.log('reset timeout ***********')
  clearTimeout(apiTimeoutID);
  startTimeout();
}

export default {
  postDataFiles,
  getDataFiles,
  putDataFiles,
  deleteDataFiles,
  startTimeout,
  resetTimeout
};
