<template>
  <v-list subheader class="pb">
    <v-list-item v-for="item in header" :key="item.text">
      <div class="popup-cb-wrapper" v-if="item.filterLabel !== 'ref'">
        <v-checkbox v-cb-focus
          :label="item.text"
          :value="item.text"
          v-model="selectedTrialsHeaderArr"
          @change="updateTrialsHeader"></v-checkbox>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'ShowHideColumns',
  props: {
    header: {type: Array, required: true},
  },
  data() {
    return {
      selectedTrialsHeaderArr: [],
    }
  },
  created() {
    this.header.forEach(header => {
      if (header.show === true) {
        this.selectedTrialsHeaderArr.push(header.text)
      }
    })
  },
  methods: {
    updateTrialsHeader() {
      this.$emit('activeHeaderChange', this.selectedTrialsHeaderArr)
    }
  }
}
</script>

<style>

</style>