<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="grey lighten-5" text v-bind="attrs" v-on="on" @click="signOut">
        SIGN OUT
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Sign Out
      </v-card-title>

      <v-card-text class="ml-n2 mr-0 pr-2">
        <div v-if="hasSignOut">
          <p class="text-h6">You have been signed out of emFACT.</p>
          <p class="text-h6">We recommend that you close the emFACT application.</p>
        </div>
        <div v-else>
          <p class="text-h6">Hang on a moment while we sign you out.</p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: "SignOutDialog",
  data() {
    return {
      dialog: false,
      hasSignOut: false
    };
  },
  methods: {
    async signOut() {
      //this.$store.dispatch('trialsStore/postStarredTrials');
      try {
        await Auth.signOut();
        this.$store.dispatch('authStore/setUser', null)
        setTimeout(() => {
          this.hasSignOut = true;
          setTimeout(() => {
            let url = `${window.location.origin}`
            window.open(url, '_self')
          },2000)
        }, 1000)
      } catch (error) {
        console.log('error signing out:', error)
      }
    }
  }
};
</script>

<style></style>