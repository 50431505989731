<template>
  <div class="text-center">
    <v-dialog :content-class="diseaseType + '-dialog-wrapper'" :value="diseaseDialog" persistent width="95vw">
      <v-card>
        <v-card-title>
          {{dialogTitle}}
          
          <v-spacer></v-spacer>
          <v-autocomplete id="stage-search" :items="treeFlatData" label="Select Node" 
            clearable hide-details
            v-model="targetNode"
            @change="getTracePathArr">
          </v-autocomplete>
          <v-btn small color="primary" @click="expandNodes" class="ml-4 mt-3">Search</v-btn>
          <v-spacer></v-spacer>

          <div class="btn-close">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
              @click="$emit('diseaseDialogClose')">mdi-close-thick</v-icon>
          </div> 
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="tree-container pa-0">
              <!-- tree component here -->
              <tree ref="tree"
                v-model="currentData"
                :height="600" 
                :data="treeData"
                duration="350"
                class="tree"
                @clickedNode="onClickNode">
              </tree>
            </v-col>
            <v-col cols="12" class="pb-0">
              <p>Disease Selected: 
                <v-chip class="rounded"
                  v-for="(item, index) in diseaseSearch" :key="item"
                  close rounded small
                  @click:close="removeDisease(index)"
                >
                  {{item}}
                </v-chip>
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn depressed color="primary" @click="addDisease">Add disease</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="outlined" @click="$emit('diseaseDialogClose')">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Tree from '@/components/collapsibleTree/Tree.vue';
import { getMatchedNodeArr } from '@/store/buildTree';

export default {
  name: 'SolidDialog',
  components: { Tree },
  props: {
    diseaseDialog: {default: false},
    dialogTitle: {required: true, type: String},
    diseaseType: {required: true, type: String}
  },
  data() {
    return {
      currentData: null,
      events: [],
      targetNode: '',
      tracePathArr: []
    };
  },
  mounted() {
    
  },
  methods: {
    async do (action) {
      if (this.currentData) {
        this.isLoading = true
        await this.$refs['tree'][action](this.currentData)
        this.isLoading = false
      }
    },
    getId (node) {
      return node.id
    },
    onClickNode (evt) {
      this.currentData = evt.element
      this.onEvent('clickedNode', evt)
    },
    onEvent (eventName, data) {
      this.events.push({eventName, data: data.data})
    },
    addDisease() {
      this.$store.dispatch('diseaseStore/addDisease', {
        type: this.diseaseType,
        diseaseName: this.currentData.data.name
      })
    },
    removeDisease(index) {
      this.$store.dispatch('diseaseStore/removeDisease', {
        type: this.diseaseType,
        diseaseIndex: index
      })
    },
    expandNodes() {
      this.$refs.tree.collapseAllChild();
      setTimeout(() => {
        this.$refs.tree.expandParticularNode(this.tracePathArr);
      }, 450)
    },
    getTracePathArr() {
      this.tracePathArr = getMatchedNodeArr(this.treeData, this.targetNode)
    }
  },
  computed: {
    treeData() {
      //TODO: cnange getTreeData(this.diseaseType)
      return this.$store.getters['diseaseStore/getSolidData']
    },
    treeFlatData() {
      return this.$store.getters['diseaseStore/getSolidFlatData']
    },
    diseaseSearch() {
      return this.$store.getters['diseaseStore/getDisease'][this.diseaseType]
    }
  }
};
</script>

<style lang="scss"></style>
