<template>
  <div id="select-search-panel">
    <div class="welcome-msg-title">Welcome to the emFACT cancer clinical trial search tool</div>
    <p class="welcome-msg-body text-center tl py-2 py-md-4">We offer two ways to search for trials. Please select an option below to begin your search (you can switch at any time):</p>
  
    <div id="search-options-wrapper" class="d-flex justify-center">
      <button class="search-option-card basic elevation-2 mx-0 mx-lg-8" data-test="basicSearch"
        @click="selectSearchType('basic')">
        <div>
          <v-icon class="white--text">mdi-auto-fix</v-icon>
        </div>
        <div>
          <p class="search-option-card-title">BASIC SEARCH</p>
          <!-- <p class="search-option-card-subtitle">Designed for novice users</p> -->
          <p class="mb-0">Recommended for those with more general information. For example, a newly diagnosed patient who does not have their molecular profile and is awaiting test results.</p>
        </div>
      </button>
      <button class="search-option-card advanced elevation-2 mx-0 mx-md-8" data-test="advancedSearch"
        @click="selectSearchType('advanced')">
        <div>
          <v-icon class="white--text">mdi-text-box-search-outline</v-icon>
        </div>
        <div>
          <p class="search-option-card-title">ADVANCED SEARCH</p>
          <!-- <p class="search-option-card-subtitle">Designed for experienced users</p> -->
          <p class="mb-0">Recommended for those with more specific information. For example, a refractory patient who has details on their treatment, molecular profile, and recent test results.</p>
        </div>
      </button>
    </div>

    <div id="disclaimer">
      <p class="large-font">What information do I need to use emFACT?</p>
      <p>The only required data you need to run a search is a diagnosis (or disease name). All of the other fields are optional, but your search results will be better if you enter as much information as you can into the Search Criteria page. Click Basic Search or Advanced Search to get started.</p>

      <p class="large-font">What is emFACT?</p>
      <p>This is Essex Management's tool to Find A Clinical Trial. We curate publicly-available clinical trial data from the National Cancer Institute (NCI) to help patients, caregivers, and providers find the best cancer clinical trials for their specific disease. This project is in support of the NCI's 
        <a href="https://www.cancer.gov/research/areas/childhood/childhood-cancer-data-initiative" target="_blank">Childhood Cancer Data Initiative</a> (CCDI) and focuses on pediatric, AYA, and young adult cancer clinical trials.</p>

      <p class="large-font">Who can use this tool?</p>
      <p>Anyone can use emFACT. Please note that this project anticipates its primary users to be parents, caregivers, and providers. While we focus on the best cancer clinical trials for children, adults that have been diagnosed with cancer during their childhood can also use this search tool. We aim to provide a focused list of the most relevant cancer clinical trials that could provide treatment for your child so you can spend less time sifting through information and more time discussing the best options available.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectSearch',
  props: {
    selectedGroup: {type: String, required: true}
  },
  methods: {
    selectSearchType(type) {
      this.$store.commit('authStore/SET_SEARCH_TYPE', type)
      setTimeout(() => {
        window.scroll({top: 0})
      }, 500)
    }
  },
}
</script>

<style>

</style>