<template>
  <v-dialog :value="videoDialog" width="70%">
    <v-card>
      <v-card-title class="text-h5 px-2">
        <!-- Play video intro msg here. Please turn on your speakerphone. -->

        <div class="btn-close">
          <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
            @click="closeIntroVideo">mdi-close-thick</v-icon>
        </div>
      </v-card-title>

      <v-card-text class="px-2">
        <video-player class="intro-video"
          ref="introVideo"
          :options="playerOptions"></video-player>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'VideoDialog',
  data() {
    return {
      playerOptions: {
        // videojs options
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "/media/emFact_demo_2023-01-30.mp4"
          //src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
        }],
        poster: "/img/emFact_poster.png",
      }
    }
  },
  methods: {
    closeIntroVideo() {
      this.player.pause();
      this.$store.commit('videoStore/UPDATE_VIDEO_DIALOG', false)
    },
  },
  computed: {
    videoDialog() {
      return this.$store.getters['videoStore/getVideoDialog']
    },
    player() {
      return this.$refs.introVideo.player
    }
  }
}
</script>

<style>

</style>