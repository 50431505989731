<template>
  <v-dialog v-model="dialog" width="550" persistent>
    <v-card>
      <v-card-title>
        Warning
      </v-card-title>

      <v-card-text class="ml-n2">
        <div>
          <p class="text-h6">You have left this application idle for 20 minutes.</p>
          <p v-if="time !== 0"><v-chip pill color="primary" class="white--text">{{secs}}</v-chip>{{secs == 1 ? 'second' : 'seconds'}} left</p>
          <p class="text-h6" v-if="time === 0">Your session has expired, please sign back in to continue.</p>
        </div>
      </v-card-text>

      <v-card-actions v-if="showBtn">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="keepMeIn" >
          STAY LOGGED IN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      dialog: true,
      time: 30 * 1000,
      timeId: null,
      showBtn: true
    };
  },
  created() {
    //set `idleDialogOpened` status to true, even waiting period passed, the dialog stay shown even user interact
    this.$store.commit('authStore/UPDATE_IDLE_DIALOG', true)
    this.timerId = setInterval(() => {
      this.time -= 500;
      /* if (!this.$store.state.idleVue.isIdle) {
        //if user interact before waiting expired, reset `idleDialogOpened` status to false, that will hide the dialog
        this.$store.commit('authStore/UPDATE_IDLE_DIALOG', false)
        clearInterval(timerId);
      } */
      if (this.time == 500) {
        this.showBtn = false;
        /* clearInterval(this.timerId);
        this.signOut() */
      } else if (this.time <= 0) {
        clearInterval(this.timerId);
        this.signOut()
      }
    }, 500);
  },
  methods: {
    async signOut() {
      //this.$store.dispatch('trialsStore/postStarredTrials');
      try {
        await Auth.signOut();
        this.$store.dispatch('authStore/setUser', null)
        let url = `${window.location.origin}`
        window.open(url, '_self')
      } catch (error) {
        console.log('error signing out:', error)
      }
    },
    keepMeIn() {
      //reset `idleDialogOpened` to false, ohterwise dialog will show up all the time
      //this.$store.commit('authStore/UPDATE_IDLE_DIALOG', false)
      //this.$router.push({name: 'Login'})
      //let url = `${window.location.origin}`
      //window.open(url, '_self')
      clearInterval(this.timerId);
      this.$store.commit('authStore/UPDATE_IDLE_DIALOG', false)
    }
  },
  computed: {
    secs() {
      return Math.floor(this.time / 1000)
    }
  }
};
</script>
