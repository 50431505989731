var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"trials-info mb-4"},[_c('div',{staticClass:"matching-trials"},[_c('strong',[_vm._v(_vm._s(_vm.recentCriteria.length))]),_vm._v(" Recent Searches found")]),_vm._m(0)]),_c('v-data-table',{staticClass:"sticky-header mobile-wrapper d-none d-md-block",attrs:{"id":"search-data-table","headers":_vm.criteriaHeader,"items":_vm.recentCriteria,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{'items-per-page-options':[5, 10, 20, 50, 100, -1]},"must-sort":"","loading":_vm.loadingRecentCriteria,"loading-text":"Loading Search","height":"500px"},on:{"pagination":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('delete-search',{attrs:{"item":item}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('search-edit',{attrs:{"search":item}})]}},{key:"item.search_info",fn:function(ref){
var value = ref.value;
return [_c('selected-criteria',{attrs:{"criteriaStr":value}})]}},{key:"item.search",fn:function(ref){
var item = ref.item;
return [_c('search-criteria',{attrs:{"item":item}})]}},{key:"item.session_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("moment")(value.trim(),'timezone', _vm.$store.getters['criteriaStore/getTZ'], 'MMM DD, YYYY hh:mm A'))+" ")]}}],null,true)}),_c('div',{staticClass:"recent-searches-card-wrapper d-md-none"},[(_vm.loadingRecentCriteria)?_c('div',{staticClass:"text-center mt-16"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',_vm._l((_vm.recentCriteria),function(item,index){return _c('div',{key:index,staticClass:"recent-searches-card elevation-2 mb-6"},[_c('div',{staticClass:"px-4 pt-4"},[_c('div',{staticClass:"prop-title"},[_vm._v("Search Name")]),_c('div',{staticClass:"prop-value"},[_vm._v(_vm._s(item.search_name))]),_c('div',{staticClass:"prop-title"},[_vm._v("Search Date/Time")]),_c('div',{staticClass:"prop-value"},[_vm._v(_vm._s(_vm._f("moment")(item.session_date.trim(),'timezone', _vm.$store.getters['criteriaStore/getTZ'], 'MMM DD, YYYY hh:mm A')))]),_c('div',{staticClass:"prop-title"},[_vm._v("Search Criteria")]),_c('div',{staticClass:"prop-value"},[_c('selected-criteria',{attrs:{"criteriaStr":item.search_info}})],1)]),_c('div',{staticClass:"btn-actions-wrapper d-flex"},[_c('div',[_c('v-btn',{attrs:{"text":"","color":"#8E389B"},on:{"click":function($event){return _vm.deleteSearchItem(item)}}},[_c('v-icon',{attrs:{"aria-label":"Edit Search Name","role":"img","aria-hidden":"true"}},[_vm._v("mdi-close-circle-outline")]),_vm._v(" REMOVE ")],1)],1),_c('div',[_c('search-edit',{attrs:{"search":item}})],1),_c('div',[_c('search-criteria',{attrs:{"item":item}})],1)])])}),0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v("Your recent searches will be available for "),_c('strong',[_vm._v(" 30 days")]),_vm._v(".")])}]

export { render, staticRenderFns }