import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import get from 'lodash/get'
import Home from '../views/Home.vue'
import Admin from '../views/Admin.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Forgot from '../views/Forgot.vue'
import Reset from '../views/Reset.vue'
import SwaggerPage from '../views/SwaggerPage'
import ShareableResults from '../views/ShareableResults'
import { Auth, Hub } from 'aws-amplify'

Vue.use(VueRouter)

async function isAuthenticated() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    //console.log('not signed in')
    return false;
  }
}

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn':
    case 'cognitoHostedUI':
      /* console.log('cognitoHostedUI signIn')
      Auth.currentAuthenticatedUser()
        .then(userData => userData)
        .catch(() => console.log('Not signed in')); */
      break;
    case 'signOut':
      //setUser(null);
      break;
    case 'signIn_failure':
    case 'cognitoHostedUI_failure':
      console.log('event', event)
      break;
  }
});

const routes = [
  // default redirect
  {path: '*', redirect: '/'},
  {path: '/', redirect: {name: 'Home'}},
  {path: '/home', name: 'Home', component: Home, meta: { requiresAuth: true }},
  {path: '/admin', name: 'Admin', component: Admin, meta: { requiresAuth: true }},
  {path: '/share/:id', name: 'Share', component: ShareableResults, props: true},

  {path: '/login', name: 'Login', component: Login},
  {path: '/register', name: 'Register', component: Register},
  {path: '/forgot', name: 'Forgot', component: Forgot},
  {path: '/reset', name: 'Reset', component: Reset},
  {path: '/api-swagger', component: SwaggerPage},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_SUBPATH,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
})

// protect authenticated routes (must have meta tag `requiresAuth` set to true)
router.beforeEach(async (to, from, next) => {
  let title = get(to, 'meta.title', '');
  document.title = title ? `${title} - emFACT` : 'emFACT';

  if (process.env.VUE_APP_SKIP_AUTH === 'true') {
		return next()
	}

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (await isAuthenticated()) { //authenticated
      let user = await Auth.currentAuthenticatedUser();
      store.dispatch('authStore/setUser', user)
      if (to.name == 'Admin') { //to admin and has admin group
        if (store.getters['authStore/getGroup'].indexOf('admin') !== -1) {
          return next()
        } else {
          console.log('admin denied')
          return next('/')
        }
      } else {
        return next()
      }
    } else {
      return next('/login')
    } 
  } else { //no need to auth
    next()
  }

  /* if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['authStore/getUser']) {
    return next('/')
  } else {
    if (to.name == 'Admin' && store.getters['authStore/getGroup'] == 'admin') {
      return next()
    } 
    next()
  } */

  //next()
})

export default router
