<template>
  <p class="content-tooltip-wrapper">
    <span>{{truncatedVal}}</span>
    <v-menu v-if="value.length > 150" open-on-hover offset-y max-width="400" content-class="content-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-on="on" v-bind="attrs" small class="ml-1">
          <v-icon>mdi-dots-horizontal </v-icon>
        </v-chip>
      </template>

      <p class="mb-0">{{value}}</p>
    </v-menu>
  </p>
</template>

<script>
export default {
  name: 'ContentWithTooltips',
  props: {
    value: { type: String, required: true }
  },
  computed: {
    truncatedVal() {
      return this.value.substring(0, 150)
    }
  }
}
</script>

<style lang="scss">
.content-tooltip-wrapper {
  margin-bottom: 0 !important;
}
.content-tooltip {
  padding: 10px;
  margin: 0 !important;
  font-size: 10px;
  background-color: #000000;
  color: #FFFFFF;
  z-index: 20 !important;
}
</style>