<template>
  <div>
    <transition enter-active-class="page-slide-in" leave-active-class="page-zoom-out" mode="out-in">
      <init-loading v-if="!dbStarted || videoDialog" key="loading" />

      <select-group-panel v-else-if="showGroupSelectionDialog" :programs="programs" 
        @chooseGroup="chooseGroup"/>

      <div v-else key="main">
        <!-- tabs -->
        <div id="top-tabs">
          <v-tabs @change="$emit('change', $event)" class="pt-3" height="42" hide-slider v-model="sectionTabIndex">
            <v-tab data-test="search-criteria"><span>Search Criteria</span></v-tab>
            <v-tab data-test="results" :disabled="!hasTrials">Results</v-tab>
          </v-tabs>
        </div>

        <v-container fluid class="px-0 py-0">
          <!-- tab content -->
          <v-tabs-items v-model="sectionTabIndex" touchless>
            <!-- criteria tab -->
            <v-tab-item>
              <div id="criteria-result-card" class="search-card pa-md-8">
                <transition enter-active-class="page-slide-in" leave-active-class="page-zoom-out" mode="out-in">
                  <select-search v-if="!searchType" :selectedGroup="selectedGroup"/>

                  <div v-else class="inner-container elevation-2 px-md-4 ml-n1">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <!-- title / clear all btn -->
                        <h1 class="page-title pt-2 pt-md-4 px-4">
                          <v-icon role="img" aria-hidden="true">mdi-text-box-search-outline</v-icon> SEARCH CRITERIA
                        </h1>
                        <p v-if="searchType == 'basic'" class="page-subtitle pt-1 px-4 pt-md-2 mb-0 mb-md-4">
                          Enter the following information for basic search. For more search options, use our 
                          <v-btn data-test="advanced" text color="primary" class="short px-0" @click="selectSearchType('advanced')">ADVANCED SEARCH</v-btn>
                        </p>
                        <p v-if="searchType == 'advanced'" class="page-subtitle pt-1 px-4 pt-md-2 mb-0 mb-md-4">
                          Enter the following information for advanced search. For a quick search, use our
                          <v-btn data-test="advanced" text color="primary" class="short px-0" @click="selectSearchType('basic')">BASIC SEARCH</v-btn>
                        </p>
                        <div id="clear-view-search" class="text-right">
                          <v-btn data-test="clear-all" text color="primary" class="short" @click="clearAllCriteria">CLEAR ALL</v-btn>
                        </div>
                        <div class="text-right">
                          <load-search-dialog v-if="selectedGroup !== 'CCDI'" ref="loadSearchDialog" :isSearching="isSearching" />
                        </div>
                      </v-col>
                    </v-row>
                    <!-- 3 cols criteria -->
                    <v-row>
                      <!-- Patient Information -->
                      <patient-info ref="patientInfo" :priorTherapyData="priorTherapyData" 
                        @zipcodeValid="updateZipcodeValid" @ageValid="updateAgeValid" />
                      <!-- Disease Details -->
                      <v-col cols="12" lg="6" class="px-8">
                        <div class="col-title b-orange">
                          <img alt="disease detail" height="20" src="@/assets/disease_detail.svg" />
                          <span>Disease Details</span>
                        </div>
                        <!-- Disease -->
                        <disease ref="disease" />
                        
                        <div v-show="searchType == 'advanced'" class="px-2">
                          <!-- Brain/CNS metastases -->
                          <btn-group-round data-test="brain"
                            label="CNS involvement" :items="['Yes', 'No', 'Unsure']" v-model="brainCNS_multiple"
                            :hasTooltip="true" :tooltip="CNSTooltip" />

                          <!-- Biomarkers -->
                          <div class="criteria-colum-title mt-8">Select Biomarker(s)</div>
                          <biomarkers ref="biomarkers" id="biomarkers-group" />
                        </div>
                      </v-col>
                      <!-- Test Results -->
                      <test-results ref="testResults" @testResultsFieldValid="updateTestResultsFieldValid" />
                    </v-row>
                    <!-- find matches -->
                    <v-row>
                      <v-col sm="10" lg="6" offset-sm="1" offset-lg="3" class="btn-search-wrapper">
                        <v-btn data-test="find-search" block class="btn-search find-search mb-4" :disabled="!formValid || isSearching" @click="preparePayload">
                          <i class="fas fa-search"></i> FIND MATCHES
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </transition>
              </div>
            </v-tab-item>

            <!-- results tab -->
            <v-tab-item>
              <results @backToCriteriaTab="sectionTabIndex = 0" />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        
      </div>
    </transition>
    <!-- <v-snackbar :timeout="timeout" v-model="diseaseAlertSnackbar">
      Please select at least one disease.

      <template v-slot:action="{ attrs }">
        <v-btn @click="diseaseAlertSnackbar = false" color="white" text v-bind="attrs" class="pr-0 mr-n4">
          <v-icon aria-label="close" role="img" aria-hidden="false">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar> -->
    <!-- <idle-dialog v-if="isIdle" /> -->
    <v-dialog v-model="diseaseAlertSnackbar" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Please add at least one disease.
        </v-card-title>

        <v-card-text></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="diseaseAlertSnackbar = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- video dialog -->
    <!-- <v-dialog v-model="videoDialog" width="70%">
      <v-card>
        <v-card-title class="text-h5 px-2">
          Play video intro msg here. Please turn on your speakerphone.

          <div class="btn-close">
            <v-icon aria-label="close dialog" role="img" aria-hidden="false" 
              @click="closeIntroVideo">mdi-close-thick</v-icon>
          </div>
        </v-card-title>

        <v-card-text class="px-2">
          <video-player class="intro-video"
            ref="introVideo"
            :options="playerOptions"></video-player>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions } from 'vuex';
import { defaultPatientInfo, defaultDisease, defaultBiomarkers, defaultTestResults } from '@/store/initState';
import fetchService from '@/services/fetchService'
import Biomarkers from '../components/Biomarkers.vue';
import BtnGroupRound from '../components/BtnGroupRound.vue';
import Disease from '../components/Disease.vue';
import LoadingMag from '../components/LoadingMag.vue';
import PatientInfo from '../components/PatientInfo.vue';
import Results from '../components/Results.vue';
import TestResults from '../components/TestResults.vue';
import IdleDialog from '../components/dialog/IdleDialog.vue';
import preparePayloadMixin from '@/mixins/preparePayloadMixin'
import LoadSearchDialog from '../components/dialog/LoadSearchDialog.vue';
import InitLoading from '../components/InitLoading.vue';
import SelectGroupPanel from '../components/SelectGroupPanel';
import SelectSearch from '../components/SelectSearch.vue';
import { Auth } from 'aws-amplify';

export default {
  name: 'Home',
  components: { InitLoading, SelectGroupPanel, Biomarkers, BtnGroupRound, Disease, LoadingMag, PatientInfo, Results, TestResults, IdleDialog, LoadSearchDialog, SelectSearch },
  data() {
    return {
      brainCNS_multiple: 2,
      //dbStarted: false,
      diseaseAlertSnackbar: false,
      fieldValid: {
        zipCode: true,
        age: true,
        wbc: true,
        platelets: true
      },
      formValid: true,
      prepareMsg: 'Initializing emFACT application engine -- brought to you by Essex Management, LLC',
      //priorTherapyData: [],
      sectionTabIndex: 0,
      timeout: 4000,
      firstTimeFindSearch: true,
      initParticipantsAttr: [],
      isSearching: false,
      groupSelectionDialog: true,
      gp: '',
      programs: [],
      CNSTooltip: `If the cancer is affecting the central nervous system (CNS) -- for example, brain metastases -- select "Yes".`,
      videoDialog: false,
      /* playerOptions: {
        // videojs options
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "/media/intro.mp4"
          //src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
        }],
        //poster: "/static/images/author.jpg",
      } */
    };
  },
  mixins: [preparePayloadMixin],
  provide() {
    return {
      $loadSavedCriteria: this.loadSavedCriteria //inject to SearchCriteria
    }
  },
  created() {
    this.brainCNS_multiple = this.$store.getters['criteriaStore/getPatientInfo'].brainCNS_multiple;
    //fetch biomarkers basic info
    if (!this.dbStarted) {
      fetchService.getDataFiles('get_emfact_programs_for_user')
        .then(response => {
          this.programs = response;
          this.$store.commit('authStore/SET_PROGRAMS', this.programs);

          this.fetchPriorTherapyData();
        })
    }
    let user = this.$store.getters['authStore/getUser']
    if (!Object.prototype.hasOwnProperty.call(user.attributes, 'custom:login_count')) {
      if (process.env.VUE_APP_SKIP_AUTH !== 'true') {
        Auth.updateUserAttributes(user, {
          'custom:login_count': '1'
        });
      }      
      //this.videoDialog = true
      this.$store.commit('videoStore/UPDATE_VIDEO_DIALOG', true)
    } else {
      if (process.env.VUE_APP_SKIP_AUTH !== 'true') {
        let count = parseInt(user.attributes['custom:login_count']) + 1;
        Auth.updateUserAttributes(user, {
          'custom:login_count': count.toString()
        });
      }
    }
  },
  methods: {
    ...mapActions('criteriaStore', {
      c_fetchPrimaryCancerData: 'fetchPrimaryCancerData',
      c_fetchPriorTherapyData: 'fetchPriorTherapyData',
      c_fetchBiomarkersData: 'fetchBiomarkersData',
      c_updateBrainCNS: 'updateBrainCNS',
      c_fetchDiseaseTreeData: 'fetchAllDiseaseData'
    }),
    ...mapActions('diseaseStore', {
      d_reset: 'reset',
      d_setDemo: 'setDemo',
      d_getTree: 'fetchDiseaseTree'
    }),
    ...mapActions('trialsStore', {
      t_findMatches: 'findMatches',
      t_fetchCancerCenterData: 'fetchCancerCenter',
      t_fetchLeadOrgsData: 'fetchLeadOrgs',
      t_fetchColInfo: 'fetchColInfo',
      t_fetchTrialsForDistanceNoUpdate: 'fetchTrialsForDistanceNoUpdate',
      t_fetchTrialsForDistanceNoUpdateArr: 'fetchTrialsForDistanceNoUpdateArr',
      t_updateZipcodeFetchTrialsForDistance: 'updateZipcodeFetchTrialsForDistance',
      t_fetchStarredTrials: 'fetchStarredTrials'
    }),
    ...mapActions('authStore', {
      a_setSelectedGroup: 'setSelectedGroup',
    }),
    /* ...mapActions('notificationStore', {
      n_hide: 'hide',
      n_show: 'show',
    }), */
    updateZipcodeValid(e) {
      this.fieldValid.zipCode = e;
      //this.updateFormValid();
    },
    updateAgeValid(e) {
      this.fieldValid.age = e
      this.updateFormValid();
    },
    updateTestResultsFieldValid(e) {
      this.fieldValid[e.fieldName] = e.value
      this.updateFormValid();
    },
    updateFormValid() {
      this.formValid = (this.fieldValid.zipCode && this.fieldValid.age && this.fieldValid.wbc && this.fieldValid.platelets) ? true : false;
    },
    clearAllCriteria(event) {
      if (event.shiftKey) {
        this.$refs.patientInfo.setDemo({
          sex_multiple: 1, //female
          age: '55',
          ageUnit: 'Years',
          chemo_multiple: 1, //no
          radiotherapy_multiple: 1, //no
          surgery_multiple: 0, //yes
          therapy: [{"code":"C116377","pref_name":"Osimertinib"}],
          brainCNS_multiple: 0
        })
        this.$refs.testResults.setDemo({
          hiv_multiple: 0, //yes
          wbc: '6000',        
          platelets: '175000',
          performance: {"name":"2: Symptomatic, in bed less than 50% of day","code":"C105725"}
        });
        this.d_setDemo({lung: ["Metastatic Lung Non-Small Cell Carcinoma"]})
        this.$refs.biomarkers.setDemo({biomarkersSelected: [{"code":"C126892","biomarker":"EGFR Exon 19 Deletion Mutation"}]})
        this.brainCNS_multiple = 0
      } else {
        this.$refs.patientInfo.reset();
        this.$refs.testResults.reset();
        this.$refs.disease.reset();
        this.d_reset();
        this.$refs.biomarkers.reset()
        this.brainCNS_multiple = 2
      }
      this.firstTimeFindSearch = true;
    },
    loadSavedCriteria(selectedCriteria, uuid) {
      this.firstTimeFindSearch = true;
      this.isSearching = true;
      let criteria = JSON.parse(selectedCriteria);
      //populate UI
      this.$refs.patientInfo.setDemo(criteria.patientInfo);
      this.$refs.disease.setDemo(criteria.disease.diseaseModifiers);
      this.d_setDemo({...defaultDisease, ...criteria.disease}); //add defaultDisease to prevent old data missing new criteria and cause compatibility issue
      this.$refs.biomarkers.setDemo(criteria.biomarkers);
      this.$refs.testResults.setDemo(criteria.testResults);
      this.brainCNS_multiple = criteria.patientInfo.brainCNS_multiple;
      this.$refs.loadSearchDialog.hideDialog();
      //set tempGeoInfo and tell zipcodeWidget ignore fetch
      this.$refs.patientInfo.setGeoArr(criteria.patientInfo.geoArr);

      //call generatePayload to start find & search
      this.generatePayload(
        {...defaultPatientInfo, ...criteria.patientInfo}, 
        {...defaultDisease, ...criteria.disease}, 
        {...this.$store.getters['criteriaStore/getDefaultBiomarkers'], ...criteria.biomarkers}, 
        {...defaultTestResults, ...criteria.testResults}, criteria.patientInfo.geoArr, [], uuid);
    },
    preparePayload() {
      //cloneDeep all these
      let patientInfo = cloneDeep(this.$store.getters['criteriaStore/getPatientInfo']);
      let disease = cloneDeep(this.$store.getters['diseaseStore/getDisease']);
      let biomarkers = cloneDeep(this.$store.getters['criteriaStore/getBiomarkers']);
      let testResults = cloneDeep(this.$store.getters['criteriaStore/getTestResults']);
      let tempGeoInfo = cloneDeep(this.$store.getters['criteriaStore/getTempGeo']);
      let currentGeoInfo = cloneDeep(this.$store.getters['criteriaStore/getGeoArr']);
      patientInfo.geoArr = tempGeoInfo;
      
      this.generatePayload(patientInfo, disease, biomarkers, testResults, tempGeoInfo, currentGeoInfo);
    },
    fetchPriorTherapyData() {
      //fetchService.getDataFiles('prior_therapy')
      this.c_fetchPriorTherapyData()
        .then(response => {
          //if (!this.dbStarted) {
            this.c_fetchPrimaryCancerData();
            this.c_fetchBiomarkersData();
            this.c_fetchDiseaseTreeData();
            //this.d_getTree({ccode: 'C4913', disease: 'gyn'}) 
            //this.d_getTree({ccode: 'C4878', disease: 'lung'}) 
            //this.d_getTree({ccode: 'C9292', disease: 'solid'})
            this.t_fetchColInfo(); //for result page
            this.t_fetchCancerCenterData(); //for result page
            this.t_fetchLeadOrgsData(); //for result page
            this.t_fetchStarredTrials().then(() => {
              this.$store.commit('criteriaStore/UPDATE_DB_STATUS'); //set dbStarted to true
              //if not have both CCDI and emFACT demo group, manually set default, 
              //if so let user select it through `SelectGroupPanel` -> chooseGroup()
              if (!( this.$store.getters['authStore/getGroup'].includes('CCDI') && this.$store.getters['authStore/getGroup'].includes('essex_demo') ) 
                && this.$store.getters['trialsStore/getCCDIStatus'] == '') {
                console.log('not both group')
                //check for each group group
                if (this.$store.getters['authStore/getGroup'].includes('CCDI')) {
                  console.log('only CCDI')
                  this.$store.commit('trialsStore/UPDATE_CCDI_STATUS', 'CCDI');
                  this.$store.commit('authStore/UPDATE_SELECTED_GROUP', 'CCDI');
                  this.chooseGroup('CCDI');
                } else if (this.$store.getters['authStore/getGroup'].includes('essex_demo')) {
                  console.log('only essex_demo')
                  this.$store.commit('trialsStore/UPDATE_CCDI_STATUS', 'essex_demo');
                  this.$store.commit('authStore/UPDATE_SELECTED_GROUP', 'essex_demo');
                  this.chooseGroup('essex_demo');
                } else { //default, for google and okta
                  this.$store.commit('trialsStore/UPDATE_CCDI_STATUS', 'CCDI');
                  this.$store.commit('authStore/UPDATE_SELECTED_GROUP', 'CCDI');
                  this.chooseGroup('CCDI');
                }
              }
            });
          //}
          //this.priorTherapyData = response;
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    chooseGroup(gp) {
      this.gp = gp;
      this.a_setSelectedGroup(this.gp)
      //if choose ccdi group
      this.$store.commit('trialsStore/UPDATE_CCDI_STATUS', this.gp);

      let shouldShowToggle = this.programs.filter(program => {
        return program.cognito_group_name == this.gp
      })[0].filter_trials;
      this.$store.commit('trialsStore/UPDATE_SHOULD_FILTER_CCDI_STATUS', shouldShowToggle);

      //after set the group, then build the biomarker basic info @criteriaStore
      //_defaultBiomarkers: {}, biomarkers: {}, _biomarkersMultipleInfo: {}
      this.setBiomarkersBasicInfo();
    },
    setBiomarkersBasicInfo() {
      //gp = essex_demo / CCDI
      let biomarkersMultipleInfo = this.programs.find(program => program.cognito_group_name == this.gp).biomarker_json
      this.$store.commit('criteriaStore/SET_BIOMARKERS_BASIC_INFO', biomarkersMultipleInfo);
    },
    selectSearchType(type) {
      //this.$emit('selectSearchType', type);
      this.$store.commit('authStore/SET_SEARCH_TYPE', type)
      setTimeout(() => {
        window.scroll({top: 0})
      }, 500)
    },
    /* closeIntroVideo() {
      console.log(this.player)
      this.player.pause();
      this.videoDialog = false;
    },
    pauseVideo() {
      this.player.pause()
    },
    playVideo() {
      this.player.play()
    } */
  },
  mounted() {
    //console.log(this.player)
  },
  computed: {
    dbStarted() {
      return this.$store.getters['criteriaStore/getDBStatus'];
    },
    hasTrials() {
      return this.$store.getters['trialsStore/getFilteredTrials'].length > 0;
    },
    priorTherapyData() {
      return this.$store.getters['criteriaStore/getPriorTherapyData']
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle || this.$store.getters['authStore/getIdleDialogStatus']
      //return this.$store.state.idleVue.isIdle
    },
    group() {
      return this.$store.getters['authStore/getGroup']
    },
    selectedGroup() {
      return this.$store.getters['authStore/getSelectedGroup']
    },
    showGroupSelectionDialog() {
      return this.selectedGroup == '' 
              && this.$store.getters['authStore/getGroup'].includes('CCDI') 
              && this.$store.getters['authStore/getGroup'].includes('essex_demo')
    },
    searchType() {
      return this.$store.getters['authStore/getSearchType']
    },
    /* player() {
      return this.$refs.introVideo.player
    } */
  },
  watch: {
    brainCNS_multiple(val) {
      this.c_updateBrainCNS(val);
    }
  }
};
</script>
