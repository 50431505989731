<template>
  <section id="loading-wrapper" class="root-section">
    <v-container class="pt-10">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <div class="text-center d-flex align-center justify-center">
            <span><img src="/img/emFACT-logo-for-darkbg-beta.png" alt="emFACT Logo"></span>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="pb-8">
        <v-spacer></v-spacer>
        <v-col cols="5">
          <v-card elevation="2" class="pa-10">
            <div>
              <p class="form-title  pb-5">
                <strong>REGISTER</strong>
              </p>

              <v-form data-test="register-form" ref="form" v-model="valid" >
                <v-text-field data-test="username" 
                  v-model="username" :rules="usernameRules" label="Username*" required ></v-text-field>
                <v-text-field data-test="name" 
                  v-model="name" :rules="nameRules" label="Name*" required ></v-text-field>
                <v-text-field data-test="email"
                  v-model="email" :rules="emailRules" label="Email*" required ></v-text-field>
                <v-text-field data-test="password" type="password"
                  hint="Must contain at least 12 characters and  at least one character from each of the following four categories: Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9) and symbol (! @ # $ % ^ &amp; *)"        
                  v-model="password" :rules="passwordRules" label="Password*" required ></v-text-field>
                <v-text-field data-test="confirm-password" type="password"
                  v-model="confirmPassword" :rules="confirmPasswordRules" label="Confirm Password*" required ></v-text-field>

                <p v-if="errorMsg" class="error--text v-messages mt-0">
                  {{ errorMsg }}
                </p>

                <v-btn data-test="register" block :disabled="!valid" @click="register" class="mt-16 btn-search" > 
                  REGISTER 
                </v-btn>
              </v-form>
            </div>
            
            <!-- <div>
              <p class="form-title  pb-5">
                <strong>CONFIRM SIGNUP</strong>
              </p>
              <p>Confirmation email has been sent to the email address you provided. Please copy the code and paste here for confirmation.</p>
              <form>
                <v-text-field data-test="confirm-code" 
                  v-model="code" :rules="codeRules" label="Confirmation code" required ></v-text-field>
                <v-btn data-test="confirm-signup" block :disabled="!valid" @click="confirmSignUp" class="mt-16 btn-search" > 
                  CONFIRM SIGNUP
                </v-btn>
              </form>
            </div> -->
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      valid: false,
      username: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      code: '',
      user: null,
      usernameRules: [
        (v) => !!v || 'Username is required',
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => {
          const pattern = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
          return pattern.test(v) || 'Email format is invalid'
        }
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => {
          const pattern = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/;
          return pattern.test(v) || 'Email format is invalid'
        }
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) => {
          return this.password == v || 'Password is not match'
        }
      ],
      codeRules: [
        (v) => !!v || 'Verification code is required',
      ],
      errorMsg: '',
    };
  },
  mounted() {
    //this.valid = false;
  },
  methods: {
    ...mapActions('authStore', {
      a_setUser: 'setUser',
    }),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async register() {
      try {
        const { user } = await Auth.signUp({
          username: this.username, 
          password: this.password,
          attributes: {
            email: this.email,
            name: this.name
          }
        });
        console.log('user', user);
        this.user = user
      } catch (error) {
        alert(error.message);
      }
    },
    async confirmSignUp() {
      try {
        await Auth.confirmSignUp(this.username, this.code);
      } catch (error) {
          console.log('error confirming sign up', error);
      }
    }
  },
};
</script>

<style lang="scss">
form[data-test='register-form'] {
  //.v-messages {display: none;}
  .error--text {
    .v-messages {
      display: flex;
    }
  }
  .v-messages__wrapper {
    margin-bottom: 10px;
  }
}
</style>
