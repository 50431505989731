import { render, staticRenderFns } from "./TabByType.vue?vue&type=template&id=abbaf72a&"
import script from "./TabByType.vue?vue&type=script&lang=js&"
export * from "./TabByType.vue?vue&type=script&lang=js&"
import style0 from "./TabByType.vue?vue&type=style&index=0&id=abbaf72a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
