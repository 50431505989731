import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'
import VueMask from 'v-mask'
import VueSession from 'vue-session'
import VueEllipseProgress from 'vue-ellipse-progress'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import idleVue from 'idle-vue'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

Amplify.configure(awsconfig)

import './styles/app.scss'
//import 'uswds/dist/css/uswds.min.css'

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(VueSession, {persist: true});
Vue.use(VueEllipseProgress);

//register moment for global use
Vue.use(VueMoment, {
  moment
})
moment.tz.setDefault('GMT');
//register eventBus
const bus = new Vue();
Object.defineProperty(Vue.prototype, '$bus', {get() {
  return this.$root.bus
}})

Vue.use(idleVue, {
  eventEmitter: bus,
  store,
  idleTime: 20 * 60 * 1000, //20 minis idle time
  startAtIdle: false,
  events: ['keydown', 'mousedown', 'touchstart']
})

Vue.use(VueVideoPlayer)

/* register filters for global use */
import '@/filters/_globalFilters';

/* register directives for global use */
import '@/directives/_globalDirectives';

new Vue({
  router,
  store,
  vuetify,
  data: {
    bus
  },
  render: h => h(App)
}).$mount('#app')
