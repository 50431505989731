function convertObjToCSV(data) {
  //create header array
  const keys = Object.keys(data[0]);

  const itemsFormatted = [
    keys.join(','), //header row
    data.map( (row) => keys.map((key) => `"${row[key]}"`).join(',') ).join('\n'),
  ].join('\n');
  //console.log('&&&&&', itemsFormatted)
  return itemsFormatted
}

function convertObjToRow(data) {
  const temp = Object.keys(data).map(props => {
    return `"${props}: ${data[props]}"`
  })
  return 'Search Criteria:\n' + temp.join('\n') + '\n\n'
}


function exportCSVFile(allData, fileName = 'emfact_search_csv.csv') {
  let data = allData.exportData;
  let criteriaData = allData.criteriaSelection;
  let csvData = convertObjToCSV(data)
  let criteriaCsvData = convertObjToRow(criteriaData)

  let blob = new Blob([criteriaCsvData, csvData], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, fileName);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export default {
  exportCSVFile
};