import { saveAs } from './filesaver.js';
import XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

function saveAsExcel(buffer, fileName) {
    const data = new Blob([buffer], {type: EXCEL_TYPE});
    saveAs(data, fileName)
}

function exportExcelFile(allData, fileName) {
  let exportData = allData.exportData;
  let criteriaData = allData.criteriaSelection;
  let header = allData.header;
  
  let outputHeader = header.filter(item => item.text !== 'Hide' && item.text !== 'Star')
    .map(item => item.value)

  let finalExportData = [];
  exportData.forEach(row => {
    let rowObj = {};
    outputHeader.forEach(key => {
      rowObj[key] = row[key]
    });
    finalExportData.push(rowObj)
  })

  const dataTableWS = XLSX.utils.json_to_sheet(finalExportData);
  const criteriaWS = XLSX.utils.json_to_sheet(criteriaData);
  const wb = XLSX.utils.book_new();

  let columnHeaderArr = header.filter(item => item.text !== 'Hide' && item.text !== 'Star')
    .map(item => item.text);
  columnHeaderArr[0] = 'id';

  //set column width
  let columnWidthArr = header.filter(item => item.text !== 'Hide' && item.text !== 'Star')
    .map(item => ({wpx: item.width}))
  XLSX.utils.sheet_add_aoa(dataTableWS, [columnHeaderArr]);
  dataTableWS['!cols'] = columnWidthArr
  /* dataTableWS['!cols'] = [{wch:6}, {wch:15}]; */
  
  //find nct_id column
  let nctIdIndex = outputHeader.findIndex(item => item == 'nct_id');
  let excelColumnName = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[nctIdIndex];

  //create link for nct_id cell
  finalExportData.forEach((row, index) => {
    dataTableWS[`${excelColumnName}${index+2}`].l = { Target: `https://www.cancer.gov/about-cancer/treatment/clinical-trials/search/v?id=${row.nct_id}&amp;r=1`, Tooltip: `Link to ${row.nct_id}` };
  })

  XLSX.utils.book_append_sheet(wb, dataTableWS, 'matches');
  XLSX.utils.book_append_sheet(wb, criteriaWS, 'criteria');

  XLSX.writeFile(wb, fileName);
  
  /* const workbook = {
      Sheets: {
        'data': dataTableWS
      },
      SheetNames: ['data']
  };
  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
  saveAsExcel(excelBuffer, fileName); */
}

export default {
  exportExcelFile
};