<template>
  <v-col cols="12" lg="3" class="px-8">
    <div class="col-title b-purple">
      <img alt="test result" height="20" src="@/assets/test_results.svg" />
      <span>Test Results</span>
    </div>
    <div v-if="searchType == 'advanced'">
      <!-- HIV -->
      <btn-group-round data-test="hiv" label="HIV" :items="['Yes', 'No', 'Unsure']"
        v-model="testResults.hiv_multiple"
      />
      <!-- Active Hepatitis B infection -->
      <btn-group-round data-test="hepatitisB" label="Active Hepatitis B infection" :items="['Yes', 'No', 'Unsure']"
        v-model="testResults.hepatitisB_multiple"
      />
      <!-- Active Hepatitis C infection -->
      <btn-group-round data-test="hepatitisC" label="Active Hepatitis C infection" :items="['Yes', 'No', 'Unsure']"
        v-model="testResults.hepatitisC_multiple"
      />
      <!-- Solid Organ Transplant Recipient -->
      <btn-group-round data-test="solidOrgan" label="Solid Organ Transplant Recipient" :items="['Yes', 'No', 'Unsure']"
        v-model="testResults.solidOrgan_multiple"
      />
      <!-- WBC -->
      <v-form v-model="wbcPlateletsFormValid" ref="wbcPlateletsForm">
        <div class="form-group pt-1">
          <v-text-field id="wbc" label="WBC (/uL)" type="number" min="0" class="no-hide-details"
            hint="Enter the WBC in /uL -- e.g. 6000" :rules="[validWBC]"
            v-model="testResults.wbc"
          ></v-text-field>
        </div>
        <!-- Platelets -->
        <div class="form-group pt-1">
          <v-text-field id="platelets" label="Platelets (/uL)" type="number" min="0" class="no-hide-details"
            hint="Enter the platelets in /uL -- e.g. 100000" :rules="[validPlatelets]"
            v-model="testResults.platelets"  
          ></v-text-field>
        </div>
      </v-form>
    </div>

    <!-- Performance Status -->
    <div class="form-group pt-1">
      <v-autocomplete id="performance-status" :items="performanceArr" label="Performance Status"
        item-text="name" item-value="ccode" return-object
        v-model="testResults.performance"
      >
      </v-autocomplete>
    </div>
  </v-col>
</template>

<script>
import { cloneDeep } from 'lodash';
import { defaultTestResults } from '@/store/initState'
import BtnGroupRound from '../components/BtnGroupRound.vue';

export default {
  name: 'TestResults',
  data() {
    return {
      wbcPlateletsFormValid: true,
      testResults: null,
      performanceArr: [
        {"name": "Unsure", "code": "C159685"},
        {"name": "0: Asymptomatic", "code": "C105722"},
        {"name": "1: Symptomatic, but fully ambulatory", "code": "C105723"},
        {"name": "2: Symptomatic, in bed less than 50% of day", "code": "C105725"},
        {"name": "3: Symptomatic, in bed more than 50% of day, but not bed-ridden", "code": "C105726"},
        {"name": "4: Bed-ridden", "code": "C105727"},
      ]
    }
  },
  components: {
    BtnGroupRound,
  },
  created() {
    this.testResults = {...this.$store.getters['criteriaStore/getTestResults']}
  },
  methods: {
    reset() {
      this.testResults = cloneDeep(defaultTestResults);
    },
    setDemo(data) {
      this.testResults = data;
      if (this.searchType == 'advanced') {
        this.$refs.wbcPlateletsForm.validate();
      }
    },
  },
  computed: {
    validWBC() {
      if (this.testResults.wbc.length > 0) {
        this.$emit('testResultsFieldValid', {fieldName: 'wbc', value: this.testResults.wbc >= 0})
        return this.testResults.wbc >= 0 || 'Invalid value'
      }
      this.$emit('testResultsFieldValid', {fieldName: 'wbc', value: true})
      return true
    },
    validPlatelets() {
      if (this.testResults.platelets.length > 0) {
        this.$emit('testResultsFieldValid', {fieldName: 'platelets', value: this.testResults.platelets >= 0})
        return this.testResults.platelets >= 0 || 'Invalid value'
      }
      this.$emit('testResultsFieldValid', {fieldName: 'platelets', value: true})
      return true
    },
    searchType() {
      return this.$store.getters['authStore/getSearchType']
    }
  },
  watch: {
    testResults: {
      deep: true,
      handler() {
        this.$store.dispatch('criteriaStore/updateTestResults', this.testResults)
      }
    }
  }
}
</script>

<style>

</style>