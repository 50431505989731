<template>
  <section id="loading-wrapper" class="root-section">
    <v-container class="pt-10">
      <v-row class="py-md-16 align-center">
        <v-spacer></v-spacer>
        <v-col cols="12" lg="5" class="order-1 order-lg-0">
          <div class="slogan text-right pr-12 py-0 d-none d-lg-block">
            <div class="pb-4">
              <img src="@/assets/tag_line.svg" height="20" width="160" alt="tag line" />
            </div>
            <p class="footer-content mb-0 pr-5">
              There are <span>4,000+</span><br />
              CLINICAL TRIALS <br/>
              from <span>64</span><br />
              NCI-Designated<br /> 
              Cancer Centers available
            </p>
            <div class="pt-7">
              <img src="@/assets/tag_line.svg" height="20" width="160" alt="tag line" />
            </div>
          </div>
          <div class="slogan text-right pr-16 d-lg-none">
            <div class="py-4 text-center">
              <img src="@/assets/tag_line.svg" height="20" width="80" alt="tag line" />
            </div>
            <p class="footer-content mb-0 text-center">
              There are <span>4,000+</span>
              CLINICAL TRIALS
              from <span>64</span>
              NCI-Designated 
              Cancer Centers available
            </p>
            <div class="pt-7 pb-4 text-center">
              <img src="@/assets/tag_line.svg" height="20" width="80" alt="tag line" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="5">
          <v-card v-show="!showNewPasswordForm" elevation="2" class="pa-10" id="login-form-card" ref="loginForm">
            <p class="form-title  pb-5">
              <strong>SIGN IN</strong>
            </p>

            <v-form v-show="!showNewPasswordForm" id="login-form" data-test="login-form" ref="form" v-model="valid">
              <v-text-field id="username" data-test="username" v-model="username" :rules="usernameRules" 
                label="Username*" required
                @keyup.enter="keyLogin"></v-text-field>
              <v-text-field id="password" data-test="password" v-model="password" :rules="passwordRules" 
                 type="password" label="Password*" required
                 @keyup.enter="keyLogin"></v-text-field>
              <p v-if="errorMsg" class="error--text v-messages mt-0">{{errorMsg}}</p>

              <div class="d-flex justify-end">
                <v-btn text color="primary" small class="pr-0" @click="gotoForgotPassword">Forgot Password?</v-btn>
              </div>

              <v-btn data-test="login" block :disabled="!valid" @click="login" class="mt-8 btn-signin">
                SIGN IN
              </v-btn>
            </v-form>

            <!-- <div class="text-center my-4 font-weight-bold d-none d-lg-block">OR</div>

            <v-btn color="primary" block outlined class="idp" @click="signInWithGoogle">
              <span style="width: 18px; margin-right: 10px;">
                <svg class="social-logo" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                  <path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path>
                  <path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path>
                  <path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path>
                  <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EA4335"></path>
                </svg>
              </span>
              
              Sign in with Google
            </v-btn> -->
          </v-card>
          <v-card  v-show="showNewPasswordForm" elevation="2" class="pa-10" id="login-form-card" ref="loginForm">
            <p class="form-title  pb-5">
              <strong>SIGN IN</strong>
            </p>

            <div v-show="showNewPasswordForm" id="new-password-form" data-test="new-password-form">
              <p>Please enter new password.</p>
              <div class="hint-message mb-8">Must contain at least 12 characters and  at least one character from each of the following four categories: Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9) and symbol (! @ # $ % ^ &amp; *)</div>

              <v-text-field data-test="new-password" type="password" 
                v-model="newPassword" :rules="newPasswordRules" label="New Password*" required persistent-hint
                @keyup.enter="submitNewPassword"></v-text-field>
              <p v-if="errorMsg" class="error--text v-messages mt-0">{{errorMsg}}</p>
              
              <v-btn data-test="login" block :disabled="!newPasswordValid" @click="submitNewPassword" class="mt-16 btn-search">
                SUBMIT
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      valid: false,
      //newPasswordValid: false,
      username: '',
      password: '',
      newPassword: '',
      showNewPasswordForm: false,
      user: null,
      usernameRules: [
        v => !!v || 'Username is required',
        //v => (v && v.length <= 10) || 'Username must be less than 10 characters',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        //v => (v && v.length <= 10) || 'Password must be less than 10 characters',
      ],
      newPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) => {
          const pattern = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/;
          return pattern.test(v) || 'Password format is invalid';
        },
      ],
      errorMsg: '',
    }
  },
  methods: {
    ...mapActions('authStore', {
      a_setUser: 'setUser',
      a_login: 'login'
    }),
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    keyLogin() {
      if (this.valid) {
        this.login()
      } else {
        this.errorMsg = "Username and Password is required"
      }
    },
    login() {
      this.errorMsg = '';
      Auth.signIn(this.username, this.password)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.showNewPasswordForm = true;
            this.user = user
          } else {
            this.a_setUser(user)
            this.$router.replace({name: 'Home'})
          }
        })     
        .catch(e => {
          //alert(e.message)
          this.errorMsg = e.message
        });
    },
    submitNewPassword() {
      Auth.completeNewPassword(
        this.user,               // the Cognito User Object
        this.newPassword,       // the new password
      ).then(user => {
        // at this time the user is logged in if no MFA required
        this.a_setUser(user)
        this.$router.replace({name: 'Home'})
      }).catch(e => {
        //alert(e.message)
        this.errorMsg = e.message
      });
    },
    gotoForgotPassword() {
      this.$router.replace({name: 'Forgot'})
    },
    signInWithGoogle() {
      Auth.federatedSignIn({provider: 'Google'})
    }
  },
  computed: {
    newPasswordValid() {
      const pattern = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/;
      return pattern.test(this.newPassword)
    }
  }
};
</script>

<style lang="scss">
form[data-test="login-form"] {
  //.v-messages {display: none;}
  .error--text {
    .v-messages {display: flex;}
  }
  .v-messages__wrapper {
    margin-bottom: 10px;
  }
}
</style>
